import {RouterProvider} from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './assets/css/style.css';
import {routes} from './Routes/Routes';
import TagManager from 'react-gtm-module'
import {ChakraProvider} from "@chakra-ui/react";
import {useEffect} from "react";
import {HelmetProvider} from 'react-helmet-async';

const tagManagerArgs = {
    gtmId: 'GTM-PN88V344'
}


function App() {
    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])
    return (
        <div>
            <HelmetProvider>
                <ChakraProvider>
                    <RouterProvider router={routes}/>
                </ChakraProvider>
            </HelmetProvider>
        </div>
    );
}

export default App;
