import React from "react";
import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import Careers from "../Pages/Careers";
import {BlogPost} from "../Layouts/BlogPost";
import ConcreteInstallation from "../Pages/Services/ConcreteInstallation";
import DecorativeConcrete from "../Pages/Services/DecorativeConcrete";
import DrivewaysPatios from "../Pages/Services/DrivewaysPatios";
import ResidentialConcrete from "../Pages/Services/ResidentialConcrete";
import CommercialConcrete from "../Pages/Services/CommercialConcrete";
import ConcreteRepair from "../Pages/Services/ConcreteRepair";
import StampedConcrete from "../Pages/Services/StampedConcrete";
import DirectoriesOnTheWeb from "../Pages/DirectoriesOnTheWeb";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                "path": "/decorative-concrete",
                "element": <DecorativeConcrete/>
            },
            {
                "path": "/driveways-patios",
                "element": <DrivewaysPatios/>
            },
            {
                "path": "/residential-concrete",
                "element": <ResidentialConcrete/>
            },
            {
                path: '/directories-on-the-web',
                element: <DirectoriesOnTheWeb/>
            },
            {
                "path": "/commercial-concrete",
                "element": <CommercialConcrete/>
            },
            {
                "path": "/concrete-repair",
                "element": <ConcreteRepair/>
            },
            {
                "path": "/concrete-installation",
                "element": <ConcreteInstallation/>
            },
            {
                "path": "/stamped-concrete",
                "element": <StampedConcrete/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

