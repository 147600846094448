import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const ConcreteRepairs = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Trusted Concrete Repair Services in Thornton, CO
        </Heading>
        <Text>
          When it comes to concrete repair in Thornton, CO, trust Thornton
          Concrete to deliver specialized services dedicated to restoring and
          enhancing the durability and aesthetic appeal of your concrete
          structures. Our team of experts is well-equipped to address a broad
          spectrum of concrete repair needs, ensuring your installations remain
          both functional and visually appealing.
        </Text>
        <Text>
          Recognizing the crucial role that concrete plays in both residential
          and commercial properties, we employ the latest techniques and
          high-quality materials to provide effective and lasting repair
          solutions. From minor cracks to major damages, our comprehensive
          repair services cover all aspects of concrete restoration.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Thorough Damage Assessment:</b> Our comprehensive evaluation
            process involves a meticulous examination to identify the root cause
            of the damage. By understanding both the symptoms and underlying
            issues, we develop a targeted repair strategy that effectively
            restores the integrity and functionality of your concrete
            structures. From surface cracks to structural weaknesses, our
            thorough assessment ensures that no aspect of the damage is
            overlooked, providing lasting solutions that address the problem at
            its source.
          </ListItem>
          <ListItem>
            <b>State-of-the-Art Repair Techniques:</b> We employ advanced repair
            methods and state-of-the-art techniques to rejuvenate and reinforce
            your concrete structures. Whether it's crack filling, resurfacing,
            or reinforcement, our skilled technicians utilize cutting-edge
            materials and technologies to restore the appearance and durability
            of your concrete surfaces. With precision and expertise, we deliver
            results that not only enhance the aesthetic appeal but also extend
            the lifespan of your concrete installations.
          </ListItem>
          <ListItem>
            <b>Focus on Preventative Solutions:</b> In addition to addressing
            immediate repair needs, we prioritize preventative solutions to
            mitigate future damage and prolong the lifespan of your concrete
            structures. By identifying potential vulnerabilities and
            implementing proactive measures, we safeguard your investment and
            minimize the risk of costly repairs down the line. From sealants to
            protective coatings, our preventative solutions are tailored to your
            specific needs, providing long-term durability and resilience.
          </ListItem>
          <ListItem>
            <b>Minimal Disruption:</b> We understand the importance of
            minimizing disruption to your daily activities or business
            operations during the repair process. Our efficient and
            non-intrusive repair methods are designed to minimize downtime and
            inconvenience, allowing you to maintain productivity and workflow
            uninterrupted. Whether it's a residential property or commercial
            facility, we strive to complete the repairs with minimal disruption
            and maximum efficiency, ensuring a smooth and hassle-free experience
            for our clients.
          </ListItem>
          <ListItem>
            <b>Customized Repair Plans:</b> We recognize that every repair
            project is unique, with its own set of challenges and requirements.
            That's why we tailor our repair plans to meet the specific needs and
            objectives of your property. From small-scale repairs to complex
            restoration projects, our experienced team collaborates closely with
            you to develop customized solutions that deliver optimal results.
            With attention to detail and a focus on quality craftsmanship, we
            ensure that your repair project is completed to your satisfaction,
            on time and within budget.
          </ListItem>
          <ListItem>
            <b>Transparent Pricing:</b> We believe in transparency and honesty
            when it comes to pricing. That's why we provide clear, upfront
            estimates with no hidden costs or surprises. Our transparent pricing
            policy empowers you to make informed decisions about your concrete
            repair needs, allowing you to budget effectively and plan
            accordingly. With Thornton Concrete, you can trust that the price
            you see is the price you'll pay, ensuring peace of mind and
            confidence in the quality of our services.
          </ListItem>
        </UnorderedList>

        <Text>
          Trust Thornton Concrete for reliable and professional concrete repair
          services in Thornton, CO. Our commitment to quality and customer
          satisfaction ensures that your concrete repairs are handled with the
          utmost care and expertise.
        </Text>
      </Stack>
    </Box>
  );
};
