import React, {lazy} from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SiteData} from "../Constants/siteData";
import {Box} from "@chakra-ui/react";
import {SEO} from "../Components/SEO/SEO";
import useBlogPosts from "../Hooks/useBlogPosts";

const BlogCards01 = lazy(() => import('../Components/BlogCards/BlogCards01'));

const Blog01 = () => {
    const {posts} = useBlogPosts()
    return (
        <div className="main_wrapper">
            <SEO description={SiteData.blog.metaData.description} title={SiteData.blog.metaData.title}/>
            <PageHeader header={SiteData.blog.h1} subtitle={SiteData.blog.heading}/>
            <Box className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(data =>
                                    <BlogCards01
                                        key={data.id}
                                        data={data}
                                    />)
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
};
export default Blog01;