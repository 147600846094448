import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Link,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList
} from "@chakra-ui/react";
import React from "react";
import concreteMeta from "../assets/images/concreteMeta.jpg";
import {posts} from "./posts";
import {CommercialConcrete} from "../Components/CommercialConcrete/CommercialConcrete";
import {DecorativeConcrete} from "../Components/DecorativeConcrete/DecorativeConcrete";
import {ResidentialConcrete} from "../Components/ResidentailConcrete/ResidentialConcrete";
import {ConcreteRepairs} from "../Components/ConcreteRepairs/ConcreteRepairs";
import {DrivewaysPatios} from "../Components/DrivewaysPatios/DrivewaysPatios";
import {ConcreteInstallation} from "../Components/ConcreteInstallation/ConcreteInstallation";
import {faqs} from "./faqs";

import {AiFillTool} from "react-icons/ai";
import {BsShieldFillCheck} from "react-icons/bs";
import {GiConcreteBag} from "react-icons/gi";
import {FaBuilding, FaHome, FaPuzzlePiece, FaRegCalendarAlt, FaRoad,} from "react-icons/fa";
import {MdBuild} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {StampedConcrete} from "../Components/StampedConcrete/StampedConcrete";

const phoneNumber = "(720) 410-5198";
const telLink = "tel:7204105198";

export const SiteData = {
    city: "Thornton, CO",
    hoursOfOpperation: "Monday - Friday 9AM-5PM",
    phoneNumber,
    telLink,
    buttonCtaText: () => (
        <Flex>
            Ready for Superior Concrete Solutions? Get Your&nbsp;
            <Text textDecoration={"underline"} display={"inline"}>
                Free Quote Today!
            </Text>
        </Flex>
    ),
    emailFooterText: `For all your spray foam insulation needs, call Spray Foam Insulation Westminster at ${phoneNumber}`,
    mobileCtaButton: "Free Estimate Today!",
    keywords:
        "Concrete Services, professional, experienced, residential, commercial, Thornton, CO.",
    footerText: "Thornton Concrete Pro, All rights reserved.",
    ogImage: concreteMeta,
    navLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services/",
        },
        {
            title: "Blog",
            href: "/blog/",
        },
        {
            title: "About",
            href: "/about/",
        },
        {
            title: "Contact Us",
            href: "/contact/",
        },
        {
            title: "Careers",
            href: "/careers/",
            hideFromTopNav: true,
        },
    ],
    careers: {
        url: "https://www.thorntonconcretepro.com/careers/",
        metaData: {
            title:
                "Join Our Team at Thornton Concrete Pro in Thornton, CO",
            description:
                "Explore career opportunities with Thornton Concrete Pro in Thornton, CO. Join our team of skilled professionals committed to quality and excellence. Apply now!",
        },
        h1: "We're Hiring - Become a Part of Thornton Concrete",
        h2: [
            "Why Join Thornton Concrete?",
            "Current Openings in Concrete Services",
            "Our Commitment to Employee Development and Satisfaction",
        ],
        headingText: {
            whyJoin:
                "Discover a workplace where your expertise in concrete is appreciated and your professional growth is nurtured. Join a team dedicated to delivering top-notch concrete services in Thornton, CO.",
            openings:
                "Check out our current job openings in various concrete services roles and find a position that matches your skills and career goals.",
            commitment:
                "At Thornton Concrete, we are invested in our team's development, offering a supportive and dynamic work environment. Your professional success is integral to our company's growth.",
        },
        content: [
            "Welcome to the Careers page at Thornton Concrete, where your career in the concrete industry can reach new heights. We're more than a team; we're a community dedicated to excellence in concrete services. Join us in making a significant impact in Thornton, CO.",
            "At Thornton Concrete, our strength lies in our people. We are on the lookout for dedicated professionals who are passionate about concrete work. Whether you have years of experience or are just starting, we offer a workplace where your skills will be developed and appreciated.",
            "Our employees enjoy a nurturing culture that promotes both personal and professional growth. We provide comprehensive training, competitive compensation, and opportunities to work with advanced concrete technology and techniques.",
            "As a key player in the Thornton community, we pride ourselves on delivering services with integrity and professionalism. We support our team with a positive work environment, teamwork built on respect, and opportunities for career advancement.",
            "Ready to join a leading concrete service provider in Thornton, CO? Explore our current job openings and find where you fit into our expanding team. From on-site work to customer service and administration, there's a place for you at Thornton Concrete.",
            "Discover your next career opportunity with us. Be part of a team that's revolutionizing the concrete industry with quality workmanship and customer satisfaction. At Thornton Concrete, we're not just a company; we're a community. Apply today and build your career with us.",
        ],
    },
    mobileNavLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services",
        },
        {
            title: "Blog",
            href: "/blog",
        },
        {
            title: "Contacst",
            href: "/contact",
        },
        {
            title: "Concrete Repair",
            href: "/concrete-repair",
        },
        {
            title: "Concrete Installation",
            href: "/concrete-installation",
        },
        {
            title: "Commercial Concrete",
            href: "/commercial-concrete",
        },
        {
            title: "Residential Concrete",
            href: "/residential-concrete",
        },
        {
            title: "Decorative Concrete",
            href: "/decorative-concrete",
        },
        {
            title: "Driveways & Patios",
            href: "/driveways-patios",
        },
    ],
    signupForm: {
        intro: () => (
            <Text>
                Introducing Thornton Concrete Pro, a leading provider of top-notch{" "}
                <a
                    href="https://www.fortthomasconcrete.com"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    concrete contractor
                </a>{" "}
                services in Thornton, Colorado. We cater to all{" "}
                <a href="https://www.newjerseyconcretecompany.com" target="_blank" rel="noreferrer">
                    concrete
                </a>{" "}
                requirements, from residential to commercial projects. We're proud to
                serve our local community with our expertise and dedication to quality.
                Ready for your next concrete project?{" "}
                <Link href={"tel:7204105198"} textDecoration={"underline"}>
                    Give us a call.
                </Link>
            </Text>
        ),
        servicesTitle: "Our Services:",
    },
    homepage: {
        url: "https://www.thorntonconcretepro.com",
        metaData: {
            title:
                "Thornton Concrete Pro | Premier Concrete Contractor in Thornton",
            description:
                "Thornton Concrete Pro: Your reliable concrete contractor. Contact us for prompt, professional, and quality service in Thornton.",
        },
        h1: "Premium Concrete Service Provider in Thornton, Colorado",
        subtitle:
            "Your Trusted Partner for All Your Concrete Needs in Thornton, Colorado",
        servicesTitle: "Why Choose Thornton Concrete Pro",
        heading: "Personal Touch",
        subheading: "ABOUT THORNTON CONCRETE",
        detail:
            "Thornton Concrete is all about delivering customized, effective solutions to every Thornton dweller.",
        description: (
            <Text>
                Here at Thornton Concrete, we're not just about getting the job done;
                we're about doing it with flair and precision. Our squad, serving the
                Thornton community, tackles everything from cozy residential
                projects to grand commercial ventures. Whether it's the art of stamped
                concrete or constructing resilient structures, we're all about crafting
                concrete solutions that are not only strong but also have that wow
                factor, making us the top dogs in concrete contracting around Thornton.
            </Text>
        ),
        img: "images/about/call_img.png",
        values: [
            {
                id: 0,
                number: 500,
                name: "Projects Completed",
                icon: MdBuild,
            },
            {
                id: 1,
                number: 20,
                name: "Experience in Industry",
                icon: FaRegCalendarAlt,
            },
            {
                id: 2,
                number: 98,
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            // {
            //     "id": 3,
            //     "number": 10,
            //     "name": "Awards and Recognitions",
            //     "icon": MdEmojiEvents
            // },
        ],
        content: [
            <Box>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Welcome to Thornton Concrete - Your Friendly Neighborhood Experts!
                </Heading>

                <Text>Hello there, friends in Thornton! 🌞 We're thrilled you stopped by. We're all about taking concrete
                    services to a new level at Thornton Concrete at the heart of sunny Thornton, Colorado. Whether
                    you're dreaming of a <a href={"https://www.dunwoodyconcreteco.com"}
                                            target={"_blank"} rel={"noreferrer"}>concrete
                        driveway</a> that makes neighbors stop and stare or a cozy patio for backyard gatherings, we've
                    got you covered. Our team is famous for being quick, wallet-friendly, and super reliable - making us
                    the top pick for <a
                        href={"https://www.euclidconcrete.com"}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        concrete contractor
                    </a> services in the area.</Text>
                <Text>But hey, we're not just about mixing and pouring concrete. At Thornton Concrete, we're in the
                    business of creating unique places. Places for making memories, growing companies, and bringing
                    communities together. From serene garden walkways to sturdy foundations for your newest venture, our
                    experienced crew, equipped with the latest concrete technology, is here to bring your dreams to
                    life.</Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Thornton Concrete: Everything Concrete Under One Roof!
                </Heading>
                <Text>
                    Need any concrete work done in Thornton, CO? Easy peasy! Just give Thornton Concrete a call at <Link
                    href={"tel:7204105198"}>(720) 410-5198</Link>. You'll find a team eager to handle all your <a
                    href="https://www.marylandheightsconcrete.com/" target={'_blank'} rel={'noreferrer'}>concrete
                    contractor</a> needs with a big smile. And guess what? We're all about fairness here. You'll get
                    honest, pressure-free quotes and flexible payment options that keep your budget happy - that's our
                    promise to you.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Custom Concrete Services Just For You!
                </Heading>
                <UnorderedList>
                    <ListItem>
                        <Link href={"/decorative-concrete/"}>Decorative Concrete</Link>:
                        Spice up your space with our snazzy designs, starring our famous <a
                        href="https://www.pompanoconcrete.com/" target={'_blank'} rel={'noreferrer'}>stamped
                        concrete</a> that's sure to turn heads. Yep, that's our bread
                        and butter as <a href={"https://www.philaconcretework.com/"} target={"_blank"}
                                         rel={"noreferrer"}>concrete contractors</a>.
                    </ListItem>
                    <ListItem>
                        <Link href={"/driveways-patios/"}>Driveways and Patios</Link>: Trust
                        our team to jazz up your outdoors with top-notch concrete work
                        that's both pretty and practical.
                    </ListItem>
                    <ListItem>
                        <Link href={"/residential-concrete/"}>Residential Concrete</Link>: As
                        the go-to concrete contractors, we're all about giving your home a
                        facelift, from rock-solid foundations to swanky stamped concrete
                        paths.
                    </ListItem>
                    <ListItem>
                        <Link href={"/commercial-concrete/"}>Commercial Concrete</Link>: Lean
                        on our know-how for tough, trendy concrete fixes that'll make your
                        business space shine.
                    </ListItem>
                    <ListItem>
                        <Link href={"/concrete-repair/"}>Concrete Repair</Link>: Our crew
                        knows their way around concrete repair, making old concrete look new
                        and strong.
                    </ListItem>
                    <ListItem>
                        <Link href={"/concrete-installation/"}>Concrete Installation</Link>:
                        Get ready for a smooth, tailored installation process, all thanks to
                        your friendly neighborhood concrete contractors.
                    </ListItem>
                    <ListItem>
                        <Link href={"/stamped-concrete/"}>Stamped Concrete</Link>: Check out
                        our stamped concrete offerings, the perfect blend of elegance and
                        value, straight from our expert concrete contractors.
                    </ListItem>
                </UnorderedList>
                <Heading as="h2" size={"md"} pb="2" pt="3">
                    Quality and Affordability Meet Here
                </Heading>
                <Text>
                    At Thornton Concrete, top-notch <a href={'https://www.huntsvilleconcreteexperts.com/'}
                                                       target={'_blank'}
                                                       rel={'noreferrer'}>concrete contractor</a> services shouldn't
                    break the bank. Expect transparent, fair pricing from us every time. We're all about combining
                    high-quality service with prices that bring a smile to your face, ensuring you get great value for
                    your investment.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Why Choose Thornton Concrete? Because We're The Rockstars of Concrete!
                </Heading>
                <UnorderedList>
                    <ListItem>
                        Speedy, dependable service? That's our signature as your friendly
                        <a href={"https://auroracoconcrete.com/"} target={"_blank"} rel={"noreferrer"}>concrete
                            contractors</a>.
                    </ListItem>
                    <ListItem>
                        From custom stamped concrete to all-around concrete work, our range
                        is as broad as our commitment to quality.
                    </ListItem>
                    <ListItem>
                        Deeply rooted in Thornton, we're the concrete contractors who
                        know what makes this place tick.
                    </ListItem>
                    <ListItem>
                        Prices that make sense and service that wows - that's the Thornton
                        Concrete way.
                    </ListItem>
                    <ListItem>
                        Big or small, we're ready for your project, showcasing our zest as
                        all-around concrete contractors.
                    </ListItem>
                </UnorderedList>
                <Text>
                    At Thornton Concrete, we're more than just your average concrete
                    contractors; we're a part of the Thornton family, committed to
                    sprucing up our community, one project at a time.
                </Text>
                <Heading as="h2" size={"md"} pb="2" pt="5">
                    Kickstart Your Concrete Adventure with Thornton Concrete
                </Heading>
                <Text>
                    Got a concrete project in mind for Thornton, CO? Look no further! Thornton Concrete is here to turn
                    your vision into reality. Reach out for a free estimate and discover why we're the buzz of the town
                    regarding concrete services. We're all about making things easy and accepting various payment
                    methods. Thanks for considering Thornton Concrete - we're excited to work with you on your next
                    concrete project!
                </Text>
                <Button
                    as={"a"}
                    href={"tel:7204105198"}
                    bg={"#17b644"}
                    fontSize={{base: "20px", md: "23px"}}
                    color={"white"}
                    fontWeight={"bold"}
                    _hover={{bg: "#06c03b", color: "white", cursor: "pointer"}}
                    p={"16px 30px"}
                    borderRadius={0}
                    mt={"2rem"}
                    lineHeight={"26px"}
                    minH={"66px"}
                    width={"100%"}
                    maxW={"400px"}
                >
                    <i className="fa fa-phone" aria-hidden="true"/>
                    &nbsp;&nbsp;Contact Us Today!
                </Button>
            </Box>,
        ],
        additionalContent: <>
            <Container maxW="container.xl">

                <Box as="section" pt={10}>
                    <Text mb={4}>
                        Situated in the heart of Thornton's thriving community, Thornton Concrete stands as your
                        reliable ally
                        for all concrete needs. Our expertise lies in turning your concepts into reality through a
                        diverse range
                        of services, including sought-after designs like stamped concrete driveways and pool decks,
                        blending
                        style and durability seamlessly.
                    </Text>
                </Box>

                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Mastering the Art of Concrete
                    </Heading>
                    <Text mb={4}>
                        Keeping pace with contemporary trends like contemporary stamped concrete, we combine
                        craftsmanship with
                        visual allure. Our experienced team devotes meticulous attention to each project, crafting
                        everything
                        from charming rustic wood stamped concrete patios inspired by nature to the refined
                        sophistication of
                        stamped concrete pathways.
                    </Text>
                </Box>

                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Comprehensive Concrete Services
                    </Heading>
                    <Text mb={4}>
                        At Thornton Concrete, our services span a wide spectrum of reliability,
                        guaranteeing excellence whether you're seeking a stamped concrete driveway
                        cost estimate or embarking on an intricate decorative concrete resurfacing project.
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong><Link href="/decorative-concrete">Decorative Concrete</Link></strong>: Enhance your
                            outdoor spaces with our decorative expertise, featuring stamped concrete around pools,
                            adding a touch of elegance to any setting.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/driveways-patios">Driveways and Patios</Link></strong>: Our driveways
                            and patios transcend mere surfaces; they serve as the welcoming embrace of your home,
                            meticulously crafted and designed to impress.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/residential-concrete">Residential Concrete</Link></strong>: Recognizing
                            the significance of a home's foundation, we extend this commitment to excellence across all
                            our residential concrete services, blending durability with style seamlessly.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/commercial-concrete">Commercial Concrete</Link></strong>: The backbone
                            of businesses seeking sturdy and appealing concrete solutions, our commercial concrete
                            services stand as a testament to reliability and quality.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/concrete-repair">Concrete Repair</Link></strong>: Beyond the new
                            installations, our concrete repair services aim to revitalize and reinforce your existing
                            concrete, ensuring its longevity.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/concrete-installation">Concrete Installation</Link></strong>: Immerse
                            yourself in seamless and tailored concrete installations, from the initial pour to the final
                            touches.
                        </ListItem>
                        <ListItem>
                            <strong><Link href="/stamped-concrete">Stamped Concrete</Link></strong>: Explore our stamped
                            concrete services where practicality meets sophistication, ideal for pool concrete
                            resurfacing or crafting picturesque stamped concrete walkways.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Thornton Concrete: Setting the Standard
                    </Heading>
                    <Text mb={4}>
                        Opting for Thornton Concrete entails selecting a company renowned for excellence. Uncover the
                        reasons why our stamped concrete services and beyond are revered in Thornton and beyond.
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Expertise:</strong> Whether it's patio resurfacing or complete exterior concrete
                            refinishing, our expertise guarantees exceptional results.
                        </ListItem>
                        <ListItem>
                            <strong>Quality:</strong> Our unwavering commitment to quality materials and craftsmanship
                            ensures your concrete installations withstand Florida's unique climate, enduring blazing sun
                            and tropical storms alike.
                        </ListItem>
                        <ListItem>
                            <strong>Customer Focus:</strong> As concrete contractors with a heart, we are dedicated to
                            comprehending and fulfilling your specific needs.
                        </ListItem>
                        <ListItem>
                            <strong>Eco-Friendly:</strong> Our concrete work is as environmentally responsible as it is
                            aesthetically and functionally beneficial to your project.
                        </ListItem>
                        <ListItem>
                            <strong>Local Savvy:</strong> With a deep understanding of Thornton's environment, our
                            concrete solutions are tailored to meet local requirements perfectly.
                        </ListItem>
                        <ListItem>
                            <strong>Premium Materials:</strong> We select only the finest materials for durability and
                            visual appeal, ensuring your investment endures.
                        </ListItem>
                        <ListItem>
                            <strong>Client-Focused Service:</strong> Your satisfaction is our top priority, and we
                            customize our services to meet your specific needs.
                        </ListItem>
                        <ListItem>
                            <strong>Sustainable Practices:</strong> Our commitment to sustainability is evident in our
                            use of eco-friendly materials and techniques.
                        </ListItem>
                        <ListItem>
                            <strong>Full-Service Offerings:</strong> From consultation to post-installation care, we
                            offer a comprehensive range of services to address all your concrete needs.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Our Methodology - Forging the Path to Excellence
                    </Heading>
                    <Text mb={4}>
                        At Thornton Concrete, our approach is carefully crafted to guarantee a seamless and gratifying
                        journey for our clients:
                    </Text>
                    <UnorderedList mb={4}>
                        <ListItem>
                            <strong>Consultation:</strong> We initiate with a comprehensive discussion to grasp your
                            requirements and assess your property's concrete needs thoroughly.
                        </ListItem>
                        <ListItem>
                            <strong>Customized Planning:</strong> Each project is meticulously tailored to align with
                            your preferences, ensuring optimal outcomes.
                        </ListItem>
                        <ListItem>
                            <strong>Expert Installation:</strong> Our adept professionals ensure a tidy, proficient, and
                            minimally disruptive installation process.
                        </ListItem>
                        <ListItem>
                            <strong>Guaranteed Quality:</strong> Following installation, we conduct thorough inspections
                            to ensure our work adheres to our stringent quality standards.
                        </ListItem>
                        <ListItem>
                            <strong>Continuous Support:</strong> Our dedication to your satisfaction extends beyond
                            project completion, offering ongoing assistance and guidance.
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Frequently Asked Questions (FAQs)
                    </Heading>
                    <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                        {faqs.map(({question, answer}, index) => (
                            <Box
                                p={5}
                                // shadow="md"
                                borderWidth="1px"
                                borderRadius="lg"
                                key={index}
                            >
                                <Heading as={"h3"} fontSize="xl">{question}</Heading>
                                <Text mt={4}>{answer}</Text>
                            </Box>
                        ))}
                    </Grid>
                </Box>
                <Box as="section" pt={10}>
                    <Heading as="h2" size="lg" mb={4}>
                        Embark on Your Concrete Venture with Us
                    </Heading>
                    <Text mb={4}>
                        Are you prepared to enrich your Thornton property with Thornton Concrete's unparalleled
                        services? Whether it's enhancing aesthetics, functionality, or exploring sustainable concrete
                        options, we're here to bring your vision to life. Reach out to us today to commence your journey
                        toward a more stunning, resilient, and efficient property.
                    </Text>
                    <Button colorScheme="blue" size="md" mb={4} as={'a'} href={telLink}>
                        Contact Us Now
                    </Button>
                </Box>
            </Container>
        </>,
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Professional Service:</b> We respond promptly and efficiently to
                        all your concrete needs. Whether you are undertaking a large-scale project or simply desire a
                        new{" "}
                        <a
                            href="https://www.desplainesconcrete.com"
                            target="blank"
                            rel="noreferrer"
                        >
                            concrete patio
                        </a>{" "}
                        for your home, our team is dedicated to providing expert assistance and guidance every step of
                        the way.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Experienced Team:</b> Our team comprises licensed, insured, and
                        highly skilled <a
                        href="https://www.millingtonconcrete.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        concrete contractors
                    </a> who boast extensive experience in handling diverse projects. From residential to commercial
                        endeavors, our professionals are proficient in executing various{" "}
                        <a
                            href="https://cambridgeroofingpros.com/"
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            concrete installations
                        </a>{" "}
                        with precision and expertise.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Competitive Pricing:</b> As the premier{" "}
                        <a
                            href={"https://www.hialeahconcretecontractorpros.com"}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            concrete company
                        </a>
                        , we prioritize offering top-notch services at competitive rates, ensuring optimal value for our
                        clients' investments. Our transparent pricing model and commitment to excellence set us apart in
                        the market, allowing us to deliver exceptional results while respecting your budget
                        constraints.{" "}
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Quality Assurance:</b> At every stage of the project, we uphold rigorous standards of quality
                        and professionalism to ensure your complete satisfaction. From meticulous planning to meticulous
                        execution, our team is dedicated to delivering superior craftsmanship and lasting results that
                        stand the test of time. Your trust in our abilities is our greatest reward, and we strive to
                        exceed your expectations with every project we undertake.{" "}
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>Local Expertise:</b> As a trusted{" "}
                        <a href={"https://edwardsvilleconcretesolutionscompany.com"}>
                            concrete company
                        </a>
                        , we pride ourselves on our deep understanding of the local community and its unique concrete
                        needs. Serving Thornton and its surrounding areas, we are committed to enhancing your property's
                        aesthetics, functionality, and value through personalized concrete solutions tailored to your
                        specific requirements. With a focus on quality, reliability, and customer satisfaction, we
                        strive to be your preferred concrete partner for projects of any scale or complexity.{" "}
                    </Text>
                ),
            },
        ],

        footerHeading: "Contact Thornton Concrete Pro",
        footerText: () => (
            <>
                For any concrete service inquiries in Thornton and the surrounding
                areas, we are here to assist you. Call us or fill out our{" "}
                <Link href={"/contact"}>online contact form</Link> for non-emergency
                inquiries. Thornton Concrete Pro is your best choice for all concrete
                solutions.
            </>
        ),
        footerText2: "Your Satisfaction is Our Top Priority",
        servicesHeader:
            "Our Comprehensive Range of Concrete Services for All Your Construction and Renovation Needs",
        servicesSubtitle: () => (
            <Text color="muted" fontSize={{base: "lg", md: "xl"}}>
                Explore our <Link href={"/services"}>suite of services</Link> – from
                concrete mixing to installation, Thornton Concrete is your one-stop
                solution for all concrete and cement-related needs
            </Text>
        ),
        featuredServices: [
            {
                name: "Decorative Concrete",
                description:
                    "Transform your outdoor spaces with our decorative concrete services. From stamped concrete to acid stained, we provide a variety of eye-catching, durable designs that enhance your property's appeal.",
                icon: BsShieldFillCheck,
                path: "/decorative-concrete/",
            },
            {
                name: "Driveways & Patios",
                description:
                    "Whether you want a new concrete driveway or patio, Thornton Concrete delivers quality, durability, and style. We bring to life your visions, enhancing the usability and aesthetics of your outdoor areas.",
                icon: FaRoad,
                path: "/driveways-patios/",
            },
            {
                name: "Residential Concrete",
                description:
                    "Specializing in residential concrete services, we cater to a wide range of projects, from foundations to walkways and slabs. We ensure high-quality workmanship that stands the test of time.",
                icon: FaHome,
                path: "/residential-concrete/",
            },
            {
                name: "Commercial Concrete",
                description:
                    "Offering commercial concrete services, we handle projects of all scales, from parking lots to large-scale building foundations. We guarantee reliability, durability, and efficiency in every job.",
                icon: FaBuilding,
                path: "/commercial-concrete/",
            },
            {
                name: "Concrete Repair",
                description:
                    "Our concrete repair service addresses issues like cracking or deterioration, restoring the strength and appearance of your structures, thus extending their lifespan.",
                icon: AiFillTool,
                path: "/concrete-repair/",
            },
            {
                name: "Concrete Installation",
                description:
                    "From planning to execution, our professional concrete installation service guarantees a seamless process and a finished product that meets your expectations and conforms to industry standards.",
                icon: GiConcreteBag,
                path: "/concrete-installation/",
            },
            {
                name: "Stamped Concrete",
                description:
                    "Elevate your property's beauty with our expert stamped concrete services. Our skilled artisans create stunning stamped concrete designs for driveways, patios, and walkways that leave a lasting impression.",
                icon: FaPuzzlePiece,
                path: "/stamped-concrete/",
            },
        ],
    },
    services: {
        url: "https://www.thorntonconcretepro.com/services",
        metaData: {
            title:
                "Thornton Concrete Services | Expert Solutions",
            description:
                "Thornton Concrete Pro offers comprehensive concrete services in Thornton, from installation to repair. Contact our reliable team for prompt solutions.",
        },
        h1: "Your Local Experts for Top-Notch Concrete Services in Thornton, CO",
        h2: "Concrete Services We Provide",
        subtext:
            "At Thornton Concrete Pro, we pride ourselves in offering a comprehensive range of concrete services designed to ensure your structures remain strong, safe, and visually appealing all year round.",
        footerHeading: "Ready to Enhance Your Property?",
        footerText2: () => (
            <>
                Connect with us at <Link href={"tel:7204105198"}>720-410-5198</Link> or
                use our online contact form for a free quote. Experience the Thornton
                Concrete Pro difference today!
            </>
        ),
        footerText: () => (
            <>
                Remember, at Thornton Concrete Pro, your projects are our top priority.
                When it comes to concrete services in Thornton, CO, look no further than
                us. We're only a call away!
            </>
        ),
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Concrete Installation:</b> Our experienced technicians know how
                        to properly install concrete to ensure long-lasting results and
                        aesthetic appeal.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Concrete Repair:</b> If you have damaged concrete on your
                        property, we can repair it quickly and effectively, restoring the
                        strength and appearance of your structure.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Concrete Resurfacing:</b> Refresh the look and extend the
                        lifespan of your concrete surfaces with our top-notch resurfacing
                        services.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Decorative Concrete:</b> Enhance the look of your property with
                        our decorative concrete services, including stamped concrete,
                        colored concrete, and more.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/decorative-concrete/"}>Decorative Concrete:</Link>
                        </b>{" "}
                        Add a touch of elegance to your property with our decorative
                        concrete services. We offer a wide range of designs and finishes to
                        choose from.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/driveways-patios/"}>Driveways and Patios: </Link>
                        </b>
                        Enhance your outdoor spaces in Thornton, Colorado with our
                        stunning driveways and patios services. We deliver top-quality,
                        durable, and visually appealing solutions tailored to your style.
                        Whether you prefer the rustic appeal of a cobblestone driveway or
                        the elegant design of a tiled patio, we have the skills and
                        experience to bring your vision to life. Contact us at 475-242-8150
                        to discuss your project and receive a free quote.
                    </Text>
                ),
            },
        ],
        whyh2: "Why Choose Thornton Concrete Pro?",
        whySubtext: () => (
            <>
                Thornton Concrete Pro has been offering unparalleled, professional
                concrete services to the residents and businesses of Thornton and its
                surrounding areas for years. We're your trusted partner for any concrete
                needs, available at your convenience at{" "}
                <Link href={"tel:7204105198"} textDecoration={"underline"}>
                    720-410-5198
                </Link>
                .
            </>
        ),
        whyServices: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>Experienced Professionals:</b> Our skilled team of concrete
                        experts has the knowledge and experience to handle any concrete
                        service task, big or small.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>Quality Service:</b> We prioritize delivering top-quality
                        services that exceed our clients' expectations.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>Reliable and Efficient:</b> We are known for our timely and
                        efficient services. Your satisfaction is our mission!
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>Local Experts:</b> As local concrete service providers, we
                        understand the unique needs and challenges of properties in
                        Thornton, Colorado.
                    </Text>
                ),
            },
        ],
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Thornton Concrete: Premier Concrete Services in Thornton, CO
                    </Heading>
                    <Text>
                        Thornton Concrete is your premier destination for exceptional
                        concrete services in Thornton, Colorado. Specializing in both
                        residential and commercial projects, we're committed to providing
                        top-quality workmanship, whether it's a new construction,
                        renovation, or repair. Our dedication to excellence and customer
                        satisfaction sets us apart as a leading service provider in the
                        region.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose Thornton Concrete?
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Experienced Professionals:</b> Our team is comprised of
                                skilled professionals with extensive experience in a diverse
                                range of concrete projects, ensuring every job is executed with
                                precision and expertise.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>State-of-the-Art Techniques:</b> We use the latest in
                                concrete technology and methods to provide durable and
                                aesthetically pleasing results. From decorative concrete to
                                robust structural work, we handle it all.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Customer-Centric Service:</b> Our services are tailored to
                                meet your specific needs. We work closely with you to ensure
                                your vision is brought to life, with satisfaction as our top
                                priority.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Competitive Pricing:</b> We believe in offering quality
                                services at fair and transparent prices. Get the best value for
                                your investment with our competitive rates.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Local Thornton Expertise:</b> Our deep understanding of
                                local regulations and environmental conditions in Thornton,
                                CO, ensures that your project is compliant and sustainable.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Our Comprehensive Concrete Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Residential and Commercial Concrete:</b> We cater to both
                                residential and commercial clients, offering everything from
                                driveway installations to large-scale building projects.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Decorative Concrete:</b> Elevate your property's appeal with
                                our decorative concrete services, including stamped, stained,
                                and colored concrete options.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Concrete Repair and Maintenance:</b> Extend the life of your
                                concrete with our repair and maintenance services, addressing
                                issues like cracking, chipping, and wear.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Custom Concrete Solutions:</b> Our team specializes in
                                creating custom concrete designs to suit your unique
                                requirements, from functional pathways to elegant patios.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Tailored Solutions for Every Project
                    </Heading>
                    <Text>
                        At Thornton Concrete, we understand that each project is unique. We
                        offer tailored solutions to fit the specific needs of your project,
                        ensuring a perfect result that aligns with your vision and budget.
                    </Text>

                    <Heading as="h3" size="lg">
                        Dedication to Quality and Reliability
                    </Heading>
                    <Text>
                        Quality and reliability are at the core of everything we do. We are
                        committed to delivering services that not only meet but exceed your
                        expectations, ensuring your project's success and your utmost
                        satisfaction.
                    </Text>

                    <Heading as="h3" size="lg">
                        Contact Thornton Concrete Today
                    </Heading>
                    <Text>
                        For top-quality concrete services in Thornton, CO, turn to
                        Thornton Concrete. Contact us today to discuss your project. We are
                        ready to bring our expertise and dedication to your next concrete
                        endeavor.
                    </Text>
                </Stack>
            </Box>
        ),
    },
    contact: {
        url: "https://www.thorntonconcretepro.com/contact/",
        metaData: {
            title: "Contact Thornton Concrete Pro | Call 720-410-5198",
            description:
                "Call 720-410-5198 to reach Thornton Concrete Pro for durable and beautiful concrete structures in Thornton, Colorado. Professional service guaranteed.",
        },
        h1: "Thornton Concrete Pro",
        subtitle:
            "Thank you for choosing Thornton Concrete Pro. We are a dedicated concrete service company servicing Thornton, Colorado and surrounding areas, providing reliable concrete services to our valued customers. Our professional and friendly team is ready to assist with all your concrete needs.",
        sectionHeading: "Why Choose Thornton Concrete Pro?",
        signUpDescription:
            "If you prefer to contact us online, please fill out the form below. We will get back to you as soon as possible.",
        feedbackText:
            "We value your feedback. If you have any questions or concerns, we'd love to hear from you. Your satisfaction is our top priority.",
        closingText: () => (
            <>
                Contact us today for all your concrete needs.
            </>
        ),
        valueProps: [
            {
                title: "Expert Craftsmanship:",
                description:
                    "Our certified concrete experts have years of experience. They know the best techniques for concrete installation, repair, and decoration. We take pride in providing exceptional service, ensuring the durability and beauty of your concrete structures.",
            },
            {
                title: "Comprehensive Concrete Services:",
                description:
                    "From regular concrete maintenance to concrete installation and resurfacing, we offer a wide range of services. Our skilled team is equipped with the right tools to take care of all your concrete needs.",
            },
            {
                title: "State-of-the-Art Equipment:",
                description:
                    "We use high-quality, modern equipment to ensure efficient and safe concrete services. Our equipment allows us to handle projects of all sizes and complexities.",
            },
            {
                title: "Client Satisfaction:",
                description:
                    "At Thornton Concrete Pro, our goal is to exceed your expectations. We are committed to providing excellent customer service and ensure a hassle-free experience from start to finish.",
            },
            {
                title: "Our promise to you:",
                description:
                    "At Thornton Concrete it is our highest priority to make our customers' lives easier during the construction process. We strive to exceed your expectations by providing excellent customer service and are committed to delivering high-quality results on every project.",
            },
        ],
    },
    commercialConcrete: {
        url: "https://www.thorntonconcretepro.com/commercial-concrete/",
        metaData: {
            title: "Commercial Concrete in Thornton, CO | Thornton Concrete",
            description:
                "Experience top-notch commercial concrete services in Thornton, CO with Thornton Concrete Pro. Contact us at 720-410-5198 for professional support.",
        },
        h1: "Quality Commercial Concrete Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Thornton Concrete is your trusted partner for professional, reliable commercial concrete services, available to support all your commercial concrete requirements.",
        body: <CommercialConcrete/>,
    },
    concreteInstallation: {
        url: "https://www.thorntonconcretepro.com/concrete-installation/",
        metaData: {
            title: "Concrete Installation in Thornton, CO | Thornton Concrete",
            description:
                "Thornton Concrete Pro offers expert concrete installation services in Thornton, CO. Contact us at 720-410-5198 for high-quality, professional results.",
        },
        h1: "Comprehensive Concrete Installation Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Choose Thornton Concrete for professional, reliable concrete installation services, ensuring your project is executed flawlessly from start to finish.",
        body: <ConcreteInstallation/>,
    },
    stampedConcrete: {
        url: "https://www.thorntonconcretepro.com/stamped-concrete-installation/",
        metaData: {
            title:
                "Stamped Concrete Installation in Thornton, CO | Thornton Concrete Pro",
            description:
                "Thornton Concrete offers expert stamped concrete installation services in Thornton, CO. Call 720-410-5198 for high-quality stamped concrete installations.",
            keywords:
                "Stamped Concrete Installation, Thornton, CO, Stamped Concrete, Concrete Services",
        },
        h1: "Best Stamped Concrete Installation in Thornton, CO",
        subtitle: "Enhance Your Space with Stamped Concrete | Thornton Concrete",
        body: <StampedConcrete/>,
    },
    concreteRepair: {
        url: "https://www.thorntonconcretepro.com/concrete-repair/",
        metaData: {
            title: "Concrete Repair in Thornton, CO | Thornton Concrete",
            description:
                "Thornton Concrete Pro offers professional and reliable concrete repair services in Thornton, CO. Contact us at 720-410-5198 for efficient solutions.",
        },
        h1: "Expert Concrete Repair Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Trust Thornton Concrete for reliable, professional concrete repair services, ensuring your structures are maintained and restored effectively.",
        body: <ConcreteRepairs/>,
    },
    decorativeConcrete: {
        url: "https://www.thorntonconcretepro.com/decorative-concrete/",
        metaData: {
            title: "Decorative Concrete in Thornton, CO | Thornton Concrete",
            description:
                "High-quality decorative concrete services in Thornton, CO. Elevate your property with our artistic solutions. Contact us today!",
        },
        h1: "Best Decorative Concrete Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Choose Thornton Concrete for creative, durable decorative concrete services, ensuring your property stands out with unique design and top-quality workmanship.",
        body: <DecorativeConcrete/>,
    },
    concreteRepairs: {
        url: "https://www.thorntonconcretepro.com/concrete-repairs/",
        metaData: {
            title: "Concrete Repairs in Thornton, CO | Thornton Concrete",
            description:
                "Thornton Concrete offers professional concrete repair services in Thornton, CO. Contact us at 720-410-5198 if your concrete structures require quick, reliable repairs.",
        },
        h1: "Concrete Repair Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Trust Thornton Concrete for quick, professional concrete repair services, ensuring your structures regain their strength and appearance as quickly as possible.",
        body: <ConcreteRepairs/>,
    },
    residentialConcrete: {
        url: "https://www.thorntonconcretepro.com/residential-concrete/",
        metaData: {
            title: "Residential Concrete in Thornton, CO | Thornton Concrete",
            description:
                "Thornton Concrete offers reliable residential concrete services in Thornton, CO. Contact us at 720-410-5198 for immediate, professional concrete services.",
        },
        h1: "Top-Notch Residential Concrete Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "At Thornton Concrete, we offer high-quality residential concrete services, ensuring reliable solutions for all your concrete needs.",
        body: <ResidentialConcrete/>,
    },
    drivewaysPatios: {
        url: "https://www.thorntonconcretepro.com/driveways-patios/",
        metaData: {
            title: "Driveways & Patios in Thornton, CO | Thornton Concrete",
            description:
                "Thornton Concrete Pro offers expert driveway and patio construction services in Thornton, CO. Contact us today for professional and reliable results.",
        },
        h1: "Professional Driveway & Patio Services in Thornton, CO | Thornton Concrete",
        subtitle:
            "Thornton Concrete is your trusted provider for efficient, safe driveway and patio services, available to enhance the aesthetic and functionality of your property.",
        body: <DrivewaysPatios/>,
    },
    blog: {
        url: "https://www.thorntonconcretepro.com/blog",
        metaData: {
            title: "Thornton Concrete Pro: Professional Solutions | Blog",
            description:
                "Get professional solutions for concrete installation, repair, and maintenance. Contact Thornton Concrete Pro for reliable concrete services.",
        },
        h1: "Thornton Concrete Pro",
        h2: "Our Blog",
        heading:
            "Thornton Concrete Pro is here to serve you with professionalism, reliability, and efficiency. Stay tuned to our blog for regular updates, and feel free to reach out to us with any questions or concerns. Trust Thornton Concrete Pro for all your concrete needs in Thornton, CO!",
        posts: posts,
    },
    testimonials: {
        h1: "Testimonials",
        heading:
            "Discover why our clients recommend us – from concrete installation to extensive repair, Thornton Concrete is the top-rated choice for all your concrete service needs in Thornton, CO",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e29026704f",
                name: "Mary R.",
                logo: undefined,
                title: "Thornton, CO",
                quote:
                    '"Last year, we embarked on a home renovation project which required a complete overhaul of our driveway. We reached out to Thornton Concrete and their team took care of everything from removal of the old concrete to installation of the new driveway. Their professionalism, workmanship, and timeliness were remarkable. For any concrete needs in Thornton, I will always trust Thornton Concrete."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425d",
                name: "David J.",
                logo: undefined,
                title: "Thornton, CO",
                quote:
                    '"I have hired Thornton Concrete on multiple occasions for both my residential and commercial properties. They have consistently delivered high-quality work, from concrete installation to repair. I\'ve always been impressed with their expertise, commitment, and the quality of their work. They leave my properties looking impeccable every time. I highly recommend them for any concrete needs in Thornton."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=b37c",
                name: "Emily R.",
                logo: undefined,
                title: "Thornton, CO",
                quote:
                    '"I\'ve collaborated with Thornton Concrete on various projects and their professionalism and skill are unmatched. Their attention to detail and ability to execute complex designs is remarkable. Every project has exceeded expectations, both in aesthetics and durability. Their team is knowledgeable, friendly, and willing to go the extra mile to ensure client satisfaction. Thornton Concrete is my go-to for all concrete-related projects."',
            },
        ],
    },
    about: {
        url: "https://www.thorntonconcretepro.com/about/",
        metaData: {
            title:
                "Premium Concrete Services in Thornton, CO | Thornton Concrete Pro",
            description:
                "Explore our comprehensive concrete services in Thornton. From concrete pouring to repairs, our expert team is ready to meet all your concrete needs.",
        },
        h1: "Concrete Services in Thornton, CO",
        h2: [
            "Custom Concrete Solutions: Tailored services for residential and commercial projects.",
            "Experienced Concrete Specialists: Our team is equipped with the skills and knowledge to handle a variety of concrete works.",
            "Wide Range of Services: Offering everything from driveways and sidewalks to decorative concrete.",
            "Sustainable Practices: We use eco-friendly methods and materials in our concrete services.",
            "Competitive Pricing: Providing affordable yet high-quality concrete services.",
        ],
        headingText: {
            commitment:
                "Committed to delivering top-notch concrete services, ensuring durability and quality in every project.",
            team: "Our team at Thornton Concrete comprises experienced professionals dedicated to craftsmanship and customer satisfaction in every concrete job.",
            services:
                "Thornton Concrete offers a diverse array of services including concrete pouring, repair, resurfacing, and decorative concrete work. We utilize advanced techniques and tools to ensure the longevity and aesthetic appeal of your concrete structures. Our services are ideal for both residential and commercial properties, ensuring a perfect blend of functionality and design.",
        },
        featuredServices: [
            {
                name: "Decorative Concrete",
                description:
                    "Transform your outdoor spaces with our decorative concrete services. From stamped concrete to acid stained, we provide a variety of eye-catching, durable designs that enhance your property's appeal.",
                icon: BsShieldFillCheck,
                path: "/decorative-concrete/",
            },
            {
                name: "Driveways & Patios",
                description:
                    "Whether you want a new concrete driveway or patio, Thornton Concrete delivers quality, durability, and style. We bring to life your visions, enhancing the usability and aesthetics of your outdoor areas.",
                icon: FaRoad,
                path: "/driveways-patios/",
            },
            {
                name: "Residential Concrete",
                description:
                    "Specializing in residential concrete services, we cater to a wide range of projects, from foundations to walkways and slabs. We ensure high-quality workmanship that stands the test of time.",
                icon: FaHome,
                path: "/residential-concrete/",
            },
            {
                name: "Commercial Concrete",
                description:
                    "Offering commercial concrete services, we handle projects of all scales, from parking lots to large-scale building foundations. We guarantee reliability, durability, and efficiency in every job.",
                icon: FaBuilding,
                path: "/commercial-concrete/",
            },
            {
                name: "Concrete Repair",
                description:
                    "Our concrete repair service addresses issues like cracking or deterioration, restoring the strength and appearance of your structures, thus extending their lifespan.",
                icon: AiFillTool,
                path: "/concrete-repair/",
            },
            {
                name: "Concrete Installation",
                description:
                    "From planning to execution, our professional concrete installation service guarantees a seamless process and a finished product that meets your expectations and conforms to industry standards.",
                icon: GiConcreteBag,
                path: "/concrete-installation/",
            },
        ],
    },
    404: {
        metaData: {
            title:
                "Page Not Found - Thornton Concrete Pro | Concrete Service | 720-410-5198",
            description:
                "Oops! The page you're looking for could not be found. Thornton Concrete Pro offers professional concrete services. Contact us for reliable help.",
            siteName: "Concrete Service in Thornton, CO | Call 720-410-5198",
        },
        h1: "404 - Oh no, you found a page that's missing its base.",
        subtitle: "Sorry, the page you are looking for does not exist.",
        p: "Whether you require concrete installation, repair, or any other concrete service, We've got you covered. Call us at 720-410-5198 for your concrete needs.",
    },
};
