import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const DrivewaysPatios = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Expert Driveway and Patio Services in Thornton, CO
        </Heading>
        <Text>
          Transform your outdoor space with Thornton Concrete, your trusted
          partner for professional driveway and patio installations in Thornton,
          CO. Our dedicated team specializes in designing and creating
          functional, aesthetically pleasing driveways and patios that not only
          enhance your property's appearance but also contribute to its overall
          value. Recognizing the importance of these features in your home or
          business, we are committed to delivering top-quality results that
          stand the test of time.
        </Text>
        <Text>
          Whether you're seeking a new installation, an upgrade, or a complete
          makeover, we possess the expertise and materials to bring your vision
          to life. Our driveways and patios are crafted to withstand the local
          climate, providing lasting beauty and functionality for years to come.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Custom Design Solutions:</b> Elevate your outdoor space with our
            custom design solutions tailored to your unique preferences and
            requirements. Whether you prefer a classic look or a contemporary
            style, we offer a diverse range of design options for your driveway
            or patio. From traditional concrete finishes to modern decorative
            styles, we work closely with you to create a stunning outdoor area
            that seamlessly blends with your property's aesthetic and functional
            needs.
          </ListItem>
          <ListItem>
            <b>Durable and High-Quality Materials:</b> We are committed to using
            only the finest quality concrete and installation techniques to
            ensure that your driveway or patio not only looks visually appealing
            but also withstands the test of time. Our dedication to using
            durable materials and meticulous craftsmanship guarantees a
            long-lasting and resilient outdoor surface that enhances the beauty
            and value of your property for years to come.
          </ListItem>
          <ListItem>
            <b>Seamless Integration:</b> Our team of experts pays close
            attention to detail to ensure that your new driveway or patio
            seamlessly integrates with your existing landscaping and outdoor
            features. By carefully considering the layout, design, and
            architectural elements of your property, we create a cohesive
            outdoor space that enhances the overall appeal and functionality of
            your home or business premises.
          </ListItem>
          <ListItem>
            <b>Expert Installation:</b> Our highly skilled professionals utilize
            the latest tools, techniques, and industry best practices to
            efficiently install your driveway or patio with precision and
            expertise. With years of experience and a commitment to excellence,
            we guarantee minimal disruption to your daily routine and ensure
            timely completion of the project, allowing you to enjoy your new
            outdoor space sooner.
          </ListItem>
          <ListItem>
            <b>Enhanced Curb Appeal:</b> Make a lasting impression with a
            well-designed driveway or patio that enhances the curb appeal of
            your property. Whether you're welcoming guests or potential buyers,
            a beautifully crafted outdoor space creates a warm and inviting
            atmosphere, leaving a positive impression and increasing the overall
            attractiveness and value of your property.
          </ListItem>
          <ListItem>
            <b>Competitive Pricing:</b> We believe in transparency and
            integrity, which is why we provide detailed estimates and
            transparent pricing for our high-quality driveway and patio
            services. With competitive rates and no hidden costs, you can trust
            us to deliver exceptional value without compromising on quality.
            From start to finish, we prioritize your satisfaction and ensure a
            seamless experience, from initial consultation to project
            completion.
          </ListItem>
        </UnorderedList>

        <Text>
          At Thornton Concrete, we are committed to delivering excellence in
          every driveway and patio project we undertake in Thornton, CO. Contact
          us today to discuss your project and discover how we can transform
          your outdoor space into a true masterpiece.
        </Text>
      </Stack>
    </Box>
  );
};
