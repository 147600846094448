import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const ResidentialConcrete = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Premier Residential Concrete Services in Thornton, CO
        </Heading>
        <Text>
          Thornton Concrete stands as a premier provider of residential concrete
          services in Thornton, CO. Our specialization lies in delivering a
          diverse range of high-quality concrete solutions crafted to enhance
          both the beauty and functionality of your home. Whether you're seeking
          new installations, repairs, or renovations, our team is well-prepared
          to address all your residential concrete needs with unparalleled
          expertise and precision.
        </Text>
        <Text>
          Whether it's installing a new concrete driveway, crafting a custom
          patio, or performing concrete repairs, we bring the same unwavering
          dedication and craftsmanship to every project. Our objective is to
          transform your vision into reality, ensuring that every aspect of the
          job not only meets but exceeds your expectations while seamlessly
          complementing your home's aesthetic.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Comprehensive Concrete Solutions:</b> Transform your residential
            property with our comprehensive range of concrete services. From
            driveways and patios to walkways, pool decks, and more, we offer a
            variety of customizable solutions tailored to meet your specific
            needs and preferences. Whether you're looking to enhance
            functionality, improve aesthetics, or increase property value, our
            expert team is here to bring your vision to life with precision and
            professionalism.
          </ListItem>
          <ListItem>
            <b>Quality Materials and Craftsmanship:</b> We are committed to
            delivering superior quality and durability in every concrete
            installation. By using only the finest materials and employing
            skilled craftsmanship, we ensure that your concrete features not
            only look aesthetically pleasing but also stand the test of time.
            From reinforced foundations to flawlessly finished surfaces, our
            attention to detail guarantees long-lasting results that exceed your
            expectations.
          </ListItem>
          <ListItem>
            <b>Customized Designs:</b> Elevate your outdoor space with
            personalized concrete designs that reflect your unique style and
            preferences. Whether you prefer sleek modern lines, rustic textures,
            or intricate patterns, our design experts collaborate closely with
            you to create bespoke concrete features that enhance your property's
            curb appeal and leave a lasting impression on guests and visitors.
          </ListItem>
          <ListItem>
            <b>Efficient and Reliable Service:</b> Our dedicated team is
            committed to delivering efficient and reliable service with minimal
            disruption to your daily routine. From project planning to
            completion, we work diligently to ensure timely execution while
            maintaining the highest standards of quality and professionalism.
            With clear communication and proactive project management, we strive
            to exceed your expectations at every stage of the process.
          </ListItem>
          <ListItem>
            <b>Professional and Experienced Team:</b> Benefit from the expertise
            of our professional team with years of experience in residential
            concrete projects. From initial consultation to final execution, our
            knowledgeable professionals are here to guide you through every step
            of the process. With a deep understanding of industry best practices
            and innovative techniques, we provide expert advice and ensure
            flawless execution to bring your concrete vision to life.
          </ListItem>
          <ListItem>
            <b>Transparent Pricing:</b> We believe in transparency and integrity
            when it comes to pricing. That's why we provide detailed estimates
            upfront, free of hidden fees or surprises. With clear and honest
            communication, you can trust us to deliver competitive pricing
            without compromising on quality. Our commitment to transparency
            ensures peace of mind and confidence in the investment you're making
            in your residential concrete project.
          </ListItem>
        </UnorderedList>

        <Text>
          At Thornton Concrete, our commitment is to deliver superior
          residential concrete services in Thornton, CO. Contact us today to
          discuss your project and discover how we can enhance your home with
          our concrete expertise.
        </Text>
      </Stack>
    </Box>
  );
};
