import React from "react";
import {Box, Button, Heading, Spacer, Stack, Text} from "@chakra-ui/react";
import {SiteData} from "./siteData";
import {SEO} from "../Components/SEO/SEO";

export const BlogPost2 = () => {
    return (
        <Box py={{base: 0, md: 0}} mt={{base: 0, md: 0}}>
            <SEO
                title="Stunning Driveways & Patios by Thornton Concrete in Thornton, Colorado"
                description="Elevate your property with Thornton Concrete's expert driveway and patio installations in Thornton. Experience premium quality and personalized design."
            />
            <Spacer height={{base: "2rem", md: "4rem"}}/>
            <div className="main_wrapper">
                <div className="container">
                    <Stack>
                        <Heading as="h2" size="md">
                            Premier Driveways and Patios in Thornton
                        </Heading>
                        <Text>
                            Introducing <strong>Thornton Concrete</strong>, your premier choice for exquisite driveways
                            and patios in Thornton, Colorado. We specialize in transforming your outdoor spaces with
                            high-quality, durable concrete solutions that not only enhance aesthetics but also add value
                            to your property. Let us bring your vision to life with our unparalleled craftsmanship.
                        </Text>

                        <Heading as="h2" size="md">
                            Custom Designs to Enhance Your Outdoor Space
                        </Heading>
                        <Text>
                            At Thornton Concrete, we understand that each property is unique. That’s why we offer custom
                            design services to ensure your driveway and patio perfectly complement your home’s
                            architecture and landscape. Whether you’re looking for sleek, modern simplicity or intricate
                            patterns and textures, our experts are here to guide you through every step of the design
                            process.
                        </Text>

                        <Heading as="h2" size="md">
                            Superior Quality and Durability
                        </Heading>
                        <Text>
                            We pride ourselves on using only the highest quality materials and state-of-the-art
                            construction techniques. This commitment to quality ensures that your driveway or patio is
                            not only stunning but also resilient against the elements and wear over time. Trust Thornton
                            Concrete for installations that combine beauty with longevity.
                        </Text>

                        <Heading as="h2" size="md">
                            Expert Installation with Minimal Disruption
                        </Heading>
                        <Text>
                            Our team of skilled professionals is dedicated to providing a smooth and efficient
                            installation process, minimizing disruption to your daily life. We pay meticulous attention
                            to detail from preparation to the final touches, ensuring a flawless finish and timely
                            completion of your project.
                        </Text>

                        <Heading as="h2" size="md">
                            Why Choose Thornton Concrete for Your Driveway and Patio Needs?
                        </Heading>
                        <Text>
                            Choosing Thornton Concrete means selecting a partner committed to excellence. Our proven
                            track record of successful projects, combined with our personalized approach and commitment
                            to customer satisfaction, makes us the ideal choice for your next driveway or patio project.
                            Experience the difference of working with a team that truly values and realizes your outdoor
                            living space goals.
                        </Text>

                        <Heading as="h2" size="md">
                            Comprehensive Services from Concept to Completion
                        </Heading>
                        <Text>
                            Beyond just installations, Thornton Concrete offers a full suite of services to support your
                            driveway and patio from concept to completion. From initial design consultation to
                            maintenance and repairs, we are with you every step of the way to ensure your outdoor spaces
                            remain pristine and functional for years to come.
                        </Text>

                        <Heading as="h2" size="md">
                            Sustainable Practices for a Greener Tomorrow
                        </Heading>
                        <Text>
                            We are committed to sustainability in all our projects. Thornton Concrete utilizes
                            eco-friendly materials and practices that reduce environmental impact without compromising
                            on quality. Choose us for sustainable solutions that you can feel good about.
                        </Text>

                        <Heading as="h2" size="md">
                            Partner with Us for Your Next Project
                        </Heading>
                        <Text>
                            Ready to transform your driveway or patio? Partner with Thornton Concrete for a seamless
                            experience from start to finish. Contact us today to discuss your project and discover how
                            we can make your outdoor space truly remarkable.
                        </Text>

                        <Button
                            as={"a"}
                            href={SiteData.telLink}
                            colorScheme="blue"
                            size="lg"
                            mb={6}
                            _hover={{color: "white"}}
                        >
                            Contact Us Today
                        </Button>
                    </Stack>
                </div>
            </div>
        </Box>
    );
};
