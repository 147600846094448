import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const CommercialConcrete = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Premier Commercial Concrete Services in Thornton, CO
        </Heading>
        <Text>
          Elevate your commercial projects with Thornton Concrete, your trusted
          partner for top-tier commercial concrete services in Thornton, CO. We
          take pride in delivering superior, durable, and visually appealing
          concrete solutions that stand the test of time.
        </Text>
        <Text>
          At Thornton Concrete, we understand the intricate demands of
          commercial concrete work. Our dedicated team is well-equipped to
          tackle projects of any size, ensuring timely completion and
          unparalleled results that enhance the aesthetic and functionality of
          your commercial space.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Bespoke Concrete Designs:</b> Elevate your commercial space with
            customized concrete designs crafted to meet your project's unique
            requirements. Our team of experts specializes in creating tailored
            solutions that seamlessly integrate with your commercial space's
            aesthetic and functional needs. From sleek and modern designs to
            rustic and textured finishes, we bring your vision to life with
            precision and creativity, ensuring a cohesive and captivating
            environment that reflects your brand identity and style.
          </ListItem>
          <ListItem>
            <b>Seasoned and Skilled Professionals:</b> Trust your commercial
            concrete project to our seasoned team of professionals who possess
            extensive experience and expertise in commercial concrete
            applications. With a keen eye for detail and a commitment to
            excellence, we deliver exceptional results that exceed expectations.
            From project inception to completion, our skilled craftsmen ensure
            superior quality and craftsmanship, ensuring that your commercial
            space stands out for all the right reasons.
          </ListItem>
          <ListItem>
            <b>Cutting-Edge Techniques and Materials:</b> We stay ahead of the
            curve by leveraging the latest advancements in concrete technology
            and premium materials. By combining innovation with craftsmanship,
            we guarantee the longevity, durability, and performance of our
            commercial concrete projects, setting new industry standards for
            quality and excellence. Whether it's advanced construction
            techniques or sustainable materials, we are dedicated to delivering
            solutions that meet the highest standards of durability,
            functionality, and aesthetics.
          </ListItem>
          <ListItem>
            <b>Efficient Project Management:</b> Rest assured that your project
            is in capable hands with our efficient project management approach.
            We understand the importance of timely completion and adherence to
            budget constraints without compromising on quality or safety. Our
            dedicated project managers oversee every aspect of your project,
            from planning and scheduling to execution and delivery, ensuring
            smooth coordination and communication at every stage. With our
            proactive approach and attention to detail, we strive to exceed your
            expectations and deliver results that surpass industry standards.
          </ListItem>
          <ListItem>
            <b>Comprehensive Services:</b> Explore a comprehensive range of
            commercial concrete services tailored to meet your diverse needs.
            From foundations and flooring to decorative concrete and parking
            lots, Thornton Concrete offers a complete solution for all your
            commercial concrete requirements. Our versatile services cater to
            various industries and applications, providing customized solutions
            that enhance the functionality, durability, and aesthetics of your
            commercial space.
          </ListItem>
          <ListItem>
            <b>Transparent Pricing:</b> We believe in fostering transparency and
            trust throughout the project. That's why we provide our clients with
            detailed estimates and transparent pricing, ensuring there are no
            hidden costs or surprises along the way. Our commitment to clear and
            honest communication enables you to make informed decisions and
            ensures that your project stays within budget. With Thornton
            Concrete, you can expect upfront pricing and unparalleled
            transparency from start to finish.
          </ListItem>
        </UnorderedList>

        <Text>
          Choose Thornton Concrete for unparalleled excellence in your
          commercial concrete projects in Thornton, CO. Our commitment to
          delivering satisfaction in every aspect of our service sets us apart.
          Elevate your commercial spaces with us today.
        </Text>
      </Stack>
    </Box>
  );
};
