import React from "react";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Link,
  ListItem,
  OrderedList,
  Tag,
  Table,
  Td,
  Text,
  Th,
  Tr,
  UnorderedList,
  VStack
} from "@chakra-ui/react";
import useBlogPosts from "../../Hooks/useBlogPosts";
import {
    createFacebookShareLink,
    formatDate,
    getBlogImage,
    shareThisBlogPostViaEmail,
    sortAndShowRecentPosts,
} from "../../Utils/metaHelpers";
import PageHeader from "../../Components/common/PageHeader";
import concrete3 from "../../assets/images/concrete3.webp";
import concrete4 from "../../assets/images/concrete4.webp";
import concrete5 from "../../assets/images/concrete5.webp";
import concrete6 from "../../assets/images/concrete6.webp";
import {BlogPost1} from "../../Constants/blogPost1";
import {BlogPost2} from "../../Constants/blogPost2";
import {BlogPost4} from "../../Constants/blogPost4";
import {BlogPost5} from "../../Constants/blogPost5";
import {posts} from "../../Constants/posts";
import {Share} from "../../Components/Share/Share";
import {useLocation} from "react-router-dom";
import {SiteData} from "../../Constants/siteData";
import {FaPhoneFlip} from "react-icons/fa6";
import {SEO} from "../../Components/SEO/SEO";

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span fontWeight={'bold'}>{text}</span>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({data}, children) => (
      <Link
        href={data.uri}
        target={`${data.uri.startsWith(SiteData.homepage.url) || data.uri.startsWith('/') ? '_self' : '_blank'}`}
        rel={`${data.uri.startsWith(SiteData.homepage.url) || data.uri.startsWith('/') ? '' : 'noopener noreferrer'}`}
      >{children}</Link>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Text py={2}>{children}</Text>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.HEADING_1]: (node, children) => <Heading as={'h1'} py={6}>{children}</Heading>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading as={'h2'} fontSize={'3xl'} pt={6}>{children}</Heading>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading as={'h3'} pt={2}>{children}</Heading>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading as={'h4'} fontSize={'xl'} pt={2}>{children}</Heading>,
    [BLOCKS.HEADING_5]: (node, children) => <Heading as={'h5'} pt={2}>{children}</Heading>,
    [BLOCKS.HEADING_6]: (node, children) => <Heading as={'h6'} pt={2}>{children}</Heading>,
    [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
    [BLOCKS.TABLE_CELL]: (node, children) => <Td>{children}</Td>,
    [BLOCKS.TABLE_ROW]: (node, children) => <Tr>{children}</Tr>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <Th>{children}</Th>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <Image
        py={5}
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
  },
};
const blogPostMapping = (id) => {
    switch (id) {
        case '0':
            return <BlogPost1 title={posts[0].title} img={concrete3}
                              subtitle={posts[0].excerpt}/>
        case '1':
            return <BlogPost2 title={posts[1].title} img={concrete5} subtitle={
                posts[1].excerpt
            }/>
        case '2':
            return <BlogPost4 title={posts[2].title} img={concrete6}
                              subtitle={posts[2].excerpt}/>

        case '3':
            return <BlogPost5 title={posts[3].title} img={concrete4}
                              subtitle={posts[3].excerpt}/>
        default:
            break;
    }
}

const BlogDetails = ({post}) => {
    const {posts} = useBlogPosts();
    const location = useLocation();
    const currentUrl = window.location.origin + location.pathname;
    return (
        <>
            <PageHeader header={post.title}/>
            <SEO
                title={post.metaTitle || post.title}
                description={post.metaDescription}
                image={post.image}
            />
            <div className="main_wrapper section">
                <div className="container">
                    <div className="">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 pe-4">
                                <div className="blog_details_inner">
                                    <div className="post_content">
                                        <Box mb={5}>
                                            <HStack justify={"space-between"}>
                                                <Tag colorScheme="blue">
                                                    {formatDate(post.createdAt)}
                                                </Tag>
                                                <Share
                                                    facebookUrl={createFacebookShareLink(currentUrl)}
                                                    emailUrl={shareThisBlogPostViaEmail(
                                                        currentUrl,
                                                        post.title
                                                    )}
                                                />
                                            </HStack>
                                        </Box>
                                        <Box
                                            textAlign={"left"}
                                            mb={5}
                                            css={`
                                                h3 {
                                                    margin: 1rem 0;
                                                    font-size: 1.4rem;
                                                    font-weight: bold;
                                                }
                                            `}
                                        >
                                            {Array.isArray(post.tags)
                                                ? blogPostMapping(post.id)
                                                : documentToReactComponents(post.body, options)}
                                        </Box>
                                        <Button
                                            mb={5}
                                            size="lg"
                                            bg={"rgba(7,44,87,0.9)"}
                                            color={"white"}
                                            rightIcon={<FaPhoneFlip/>}
                                            as={"a"}
                                            href={SiteData.telLink}
                                            _hover={{textDecoration: "none"}}
                                        >
                                            Call to schedule a FREE consultation
                                        </Button>
                                        <div className="post_img">
                                            <Image
                                                src={post.image}
                                                alt={post?.imageAltText}
                                                aspectRatio={3 / 2}
                                                objectFit={"cover"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="sidebar">
                                    <div id="recent-posts-1" className="widget">
                                        <h4 className="widget_title">
                                            Recent Posts
                                            <span className="title_line"></span>
                                        </h4>
                                        <div className="sidebar_recent_posts">
                                            <VStack spacing={5}>
                                                {sortAndShowRecentPosts(posts, post).map((pst) => {
                                                    return (
                                                        <Box>
                                                            <Link
                                                                href={`/blog/${pst.slug}`}
                                                                _hover={{color: "initial"}}
                                                            >
                                                                <VStack spacing={0} align={"left"}>
                                                                    <Image
                                                                        className="primary_img"
                                                                        src={pst.image || getBlogImage(pst.id)}
                                                                        objectFit={"cover"}
                                                                        alt={pst?.imageAltText}
                                                                        aspectRatio={3 / 2}
                                                                        mb={2}
                                                                    />
                                                                    <Text
                                                                        fontSize={"xl"}
                                                                        fontWeight={"bold"}
                                                                        color={"#051050"}
                                                                        mb={2}
                                                                    >
                                                                        {pst.title}
                                                                    </Text>
                                                                    <Box>
                                                                        <Tag mb={2}>
                                                                            {formatDate(pst.createdAt)}
                                                                        </Tag>
                                                                    </Box>
                                                                    <Text
                                                                        noOfLines={3}
                                                                        _hover={{color: "initial"}}
                                                                    >
                                                                        {pst.excerpt}
                                                                    </Text>
                                                                </VStack>
                                                            </Link>
                                                        </Box>
                                                    );
                                                })}
                                            </VStack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default BlogDetails;
