import React, {lazy} from 'react';
import Slider from '../../Containers/ThemeSliders/Slider';
import {SiteData} from "../../Constants/siteData";
import {SEO} from "../../Components/SEO/SEO";
import {Box} from "@chakra-ui/react";
import Experience02 from "../../Containers/Experiences/Experience02";

const Blog = lazy(() => import('../../Containers/Blogs/Blog'));
const FunFact02 = lazy(() => import('../../Containers/Funfacts/FunFact02'));
const Banner = lazy(() => import('../../Containers/Banner'));
const Testimonial01 = lazy(() => import('../../Containers/Testimonials/Testimonial01'));
const Map = lazy(() => import('../../Components/ContactElements/Map'));
const ExperiencesWide = lazy(() => import('../../Containers/ExperiencesWide/Experience'));
const AdditionalContent = lazy(() => import('../../Components/AdditionalContent/AdditionalContent'));
const ContactFormSection = lazy(() => import('../../Components/ContactFormSection/ContactFormSection'));
const HomeSection = lazy(() => import('../../Constants/homeSection'));


const Home01 = () => {
    return (
        <div className="main_wrapper">
            <SEO/>
            <Slider/>

            <ExperiencesWide/>
            <Experience02/>
            <FunFact02/>
            <HomeSection/>
            <Testimonial01 pb='pd_btom_80'/>
            <Blog/>
            <Banner
                title={SiteData.contact.closingText()}
                heading='Call Us Anytime'
                phone={SiteData.phoneNumber}
                bannerType='banner banner_bg_color'
            />
            <ContactFormSection data={[{
                id: '1',
                iconClass: 'ion-ios-telephone-outline',
                infoBody01: 'Call us Today!',
                infoBody02: SiteData.phoneNumber,
                link: SiteData.telLink,
                cta: 'Provide some information and get a FREE quote today',
                heading: 'Get a FREE estimate'
            }]}/>
            <AdditionalContent/>
            <Box pt={0} mb={0} minW={'full'}>
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        <Map/>
                    </div>
                </div>
            </Box>

        </div>
    );
};

export default Home01;