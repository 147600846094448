import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const ConcreteInstallation = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Expert Concrete Installation Services in Thornton, CO
        </Heading>
        <Text>
          Elevate your construction or renovation project with Thornton
          Concrete, the go-to provider for professional concrete installation
          services in Thornton, CO. Our unwavering commitment to excellence
          ensures high-quality, durable, and precisely installed concrete across
          various applications.
        </Text>
        <Text>
          Specializing in both new construction and renovations, Thornton
          Concrete brings a superior level of craftsmanship and meticulous
          attention to detail to every project. Our concrete installation
          services are tailored to meet the unique needs of each client,
          promising a seamless and successful project completion.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Diverse Application Expertise:</b> Benefit from our extensive
            experience in a wide range of concrete installations, including
            driveways, patios, walkways, and structural foundations. Our team's
            versatility ensures proficiency in every aspect of our work,
            allowing us to tackle diverse projects with precision and expertise.
          </ListItem>
          <ListItem>
            <b>Advanced Techniques for Lasting Results:</b> We employ the latest
            methods and technology to ensure that our concrete installations not
            only boast aesthetic appeal but also deliver structural integrity
            and long-lasting durability. From innovative construction techniques
            to advanced materials, we prioritize quality and innovation to
            achieve results that exceed industry standards and stand the test of
            time.
          </ListItem>
          <ListItem>
            <b>Custom Design and Planning:</b> Collaborate with our team of
            experts for personalized project planning and design services. We
            work closely with you to understand your vision, preferences, and
            functional requirements, ensuring that the final product aligns
            seamlessly with your expectations. From concept to completion, we
            strive to create bespoke concrete installations that enhance the
            beauty and functionality of your space.
          </ListItem>
          <ListItem>
            <b>Quality Materials for Enduring Durability:</b> Rely on Thornton
            Concrete to use only the highest quality materials for your
            installations. Our commitment to excellence means that we source
            premium materials that can withstand Thornton's climate challenges
            and ensure enduring durability. With our dedication to quality
            craftsmanship and materials, you can trust that your installation
            will stand the test of time.
          </ListItem>
          <ListItem>
            <b>Efficient and Timely Execution:</b> Experience smooth project
            management with Thornton Concrete. We prioritize efficient and
            timely completion while minimizing disruption to your routine or
            business operations. Our streamlined processes and proactive
            approach to project management ensure that we deliver on time, every
            time, without compromising on quality or safety.
          </ListItem>
          <ListItem>
            <b>Transparent and Competitive Pricing:</b> Enjoy clear and upfront
            pricing with Thornton Concrete. We believe in transparency and
            fairness, which is why our detailed estimates reflect our commitment
            to providing value for your investment. With no hidden fees or
            surprises, you can trust that the price you see is the price you'll
            pay. Our competitive pricing ensures that you receive exceptional
            quality and service at a fair and competitive rate.
          </ListItem>
        </UnorderedList>

        <Text>
          Trust Thornton Concrete for top-tier concrete installation services in
          Thornton, CO. Our dedication to quality, efficiency, and customer
          satisfaction makes us the ideal choice for your next project. Elevate
          your spaces with precision and expertise.
        </Text>
      </Stack>
    </Box>
  );
};
