import React from "react";
import { Box, Button, Heading, Spacer, Stack, Text } from "@chakra-ui/react";
import { SiteData } from "./siteData";
import { SEO } from "../Components/SEO/SEO";

export const BlogPost1 = () => {
  return (
    <Box py={{ base: 0, md: 0 }} mt={{ base: 0, md: 0 }}>
      <SEO
        title="Top Quality Commercial Concrete Services in Thornton, Colorado"
        description="Discover professional, reliable commercial concrete services in Thornton. Our expertise covers everything from foundations to decorative concrete."
      />
      <Spacer height={{ base: "2rem", md: "4rem" }} />
      <div className="main_wrapper">
        <div className="container">
          <Stack>
            <Heading as="h2" size="md">
              Expert Commercial Concrete Services in Thornton
            </Heading>
            <Text>
              Welcome to <strong>Thornton Concrete</strong>, your trusted
              partner for exceptional commercial concrete services in Thornton,
              Colorado. Our unwavering commitment to quality and precision sets
              us apart in the construction industry. With our expertise and
              dedication, we deliver reliable solutions for all your commercial
              concrete needs.
            </Text>

            <Heading as="h2" size="md">
              Experienced Professionals at Your Service
            </Heading>
            <Text>
              Our team, composed of seasoned professionals, brings extensive
              expertise to every commercial concrete project. Whether tackling
              complex foundations or crafting intricate decorative designs, we
              ensure precision and excellence at every step. With years of
              experience and a passion for excellence, we guarantee superior
              results that meet your highest expectations.
            </Text>

            <Heading as="h2" size="md">
              Tailored Concrete Solutions for Every Need
            </Heading>
            <Text>
              From expansive foundations to elegant walkways and parking lots,
              Thornton Concrete offers comprehensive solutions tailored to meet
              your project's specific needs. Our team works closely with you to
              understand your requirements and deliver customized concrete
              solutions that ensure durability, functionality, and aesthetic
              appeal. With our attention to detail and commitment to quality,
              your project is in good hands.
            </Text>

            <Heading as="h2" size="md">
              Innovative Techniques and Premium Materials
            </Heading>
            <Text>
              Staying ahead with the latest construction techniques and
              utilizing high-quality materials, we ensure the durability and
              aesthetic appeal of your concrete structures, contributing to
              their long-term success. Thornton Concrete is committed to
              utilizing innovative techniques and premium materials to deliver
              superior results that withstand the test of time and enhance the
              value of your commercial property.
            </Text>

            <Heading as="h2" size="md">
              Putting Our Customers First
            </Heading>
            <Text>
              At Thornton Concrete, your satisfaction is our priority. We engage
              closely with you to understand your vision and strive to exceed
              your expectations with every service we provide. Our
              customer-centric approach ensures that your needs are met with
              professionalism, transparency, and a dedication to delivering
              results that surpass your expectations.
            </Text>

            <Heading as="h2" size="md">
              Why Choose Thornton Concrete?
            </Heading>
            <Text>
              Choose us for unparalleled expertise in commercial concrete
              services in Thornton, Colorado. We guarantee professionalism,
              exceptional craftsmanship, and a dedication to the success of your
              project. With Thornton Concrete, you can trust that your
              commercial concrete project is in reliable hands, delivering
              results that meet the highest standards of quality and excellence.
            </Text>

            <Heading as="h2" size="md">
              Comprehensive Maintenance and Support
            </Heading>
            <Text>
              Thornton Concrete doesn't just stop at installation; we provide
              comprehensive maintenance and support services to ensure the
              longevity and performance of your commercial concrete structures.
              From routine inspections to repairs and maintenance, our team is
              committed to preserving the integrity and functionality of your
              concrete investments.
            </Text>

            <Heading as="h2" size="md">
              Sustainable Concrete Solutions
            </Heading>
            <Text>
              As part of our commitment to sustainability, Thornton Concrete
              offers eco-friendly concrete solutions that minimize environmental
              impact without compromising on quality or performance. Our
              sustainable practices and materials contribute to a greener future
              while providing durable and reliable concrete solutions for your
              commercial projects.
            </Text>

            <Heading as="h2" size="md">
              Collaborative Project Management
            </Heading>
            <Text>
              We believe in transparent communication and collaboration
              throughout the project lifecycle. Our collaborative project
              management approach ensures that your vision is realized within
              budget and on schedule. From concept to completion, we work
              closely with you to ensure a seamless and successful project
              delivery.
            </Text>

            <Button
              as={"a"}
              href={SiteData.telLink}
              colorScheme="blue"
              size="lg"
              mb={6}
              _hover={{ color: "white" }}
            >
              Contact Us Today
            </Button>
          </Stack>
        </div>
      </div>
    </Box>
  );
};
