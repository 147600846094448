import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const StampedConcrete = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Premier Concrete Installation Services in Thornton, CO
        </Heading>
        <Text>
          Thornton Concrete stands out as the premier provider of professional
          concrete installation services in Thornton, CO. Our team is dedicated
          to delivering high-quality, durable, and precisely installed concrete
          for a variety of applications. Whether you're embarking on new
          construction or renovating existing structures, we bring the highest
          level of craftsmanship and attention to detail to every project,
          ensuring outstanding results.
        </Text>
        <Text>
          Our concrete installation services are meticulously tailored to meet
          the unique needs of each client, guaranteeing a seamless and
          successful completion of your project. We take pride in using the best
          materials and state-of-the-art techniques to consistently deliver
          exceptional results that stand the test of time.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Expertise in Diverse Applications:</b> With our extensive
            experience in various concrete installations, including driveways,
            patios, walkways, and structural foundations, we bring a wealth of
            versatility and proficiency to every project. Our team is equipped
            with the skills and knowledge to handle diverse applications,
            ensuring that each project is completed to the highest standards of
            quality and craftsmanship.
          </ListItem>
          <ListItem>
            <b>Advanced Installation Techniques:</b> We leverage the latest
            methods and technology to ensure that each concrete installation not
            only meets aesthetic standards but also boasts structural integrity
            and durability. By staying abreast of industry advancements, we
            employ advanced techniques that guarantee long-lasting results,
            providing you with a concrete installation that stands the test of
            time.
          </ListItem>
          <ListItem>
            <b>Custom Design and Planning:</b> Our collaborative approach
            involves working closely with you to plan and design your project
            according to your unique vision and functional requirements. From
            concept to execution, we prioritize open communication and
            collaboration, ensuring that the final product aligns perfectly with
            your expectations and specifications.
          </ListItem>
          <ListItem>
            <b>Quality Materials for Durability:</b> We exclusively use
            high-quality concrete materials sourced from trusted suppliers. Our
            commitment to using premium materials ensures that your installation
            is resilient and durable, capable of withstanding the challenges of
            the Thornton climate and maintaining its integrity for years to
            come.
          </ListItem>
          <ListItem>
            <b>Efficient and Timely Execution:</b> Our streamlined project
            management processes ensure that your concrete installation is
            completed efficiently and on schedule. We understand the importance
            of minimizing disruption to your daily routine or business
            operations, which is why we prioritize timely execution without
            compromising on quality. With our efficient approach, you can trust
            that your project will be completed with minimal delays and maximum
            professionalism.
          </ListItem>
          <ListItem>
            <b>Transparent and Competitive Pricing:</b> We believe in
            transparency and fairness when it comes to pricing. That's why we
            provide clear, upfront pricing with no hidden fees or surprises. Our
            detailed estimates reflect our commitment to fairness and value,
            ensuring that you receive quality service at a competitive price.
            With Thornton Concrete, you can trust that the price you see is the
            price you'll pay, allowing you to budget effectively and plan
            accordingly for your concrete installation needs.
          </ListItem>
        </UnorderedList>

        <Text>
          Choose Thornton Concrete for top-tier concrete installation services
          in Thornton, CO. Our dedication to quality, efficiency, and customer
          satisfaction makes us the ideal choice for your next project.
        </Text>
      </Stack>
    </Box>
  );
};
