import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const DecorativeConcrete = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Elevate Your Property with Artistic Decorative Concrete in Thornton,
          CO
        </Heading>
        <Text>
          Discover the transformative power of decorative concrete with Thornton
          Concrete, the premier provider of decorative concrete services in
          Thornton, CO. We specialize in turning ordinary concrete into
          stunning, artistic surfaces that not only enhance the beauty but also
          add substantial value to your property. Our team of skilled
          professionals is dedicated to creating a diverse range of decorative
          concrete finishes tailored to your unique aesthetic preferences.
        </Text>
        <Text>
          From vibrant colors and intricate patterns to elegant textures, our
          decorative concrete services are ideal for personalizing driveways,
          patios, walkways, and more. We merge artistic craftsmanship with
          durable materials, ensuring that our surfaces are not only visually
          appealing but also long-lasting and easy to maintain.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Customized Design Options:</b> Explore a multitude of design
            choices tailored to your preferences and needs. From intricate
            stamped patterns to elegant acid staining, sleek polished finishes,
            and vibrant colored concrete, we offer endless possibilities for
            customization. Our design experts collaborate closely with you to
            bring your vision to life, ensuring that every detail reflects your
            personal style and enhances the beauty of your space.
          </ListItem>
          <ListItem>
            <b>Expert Craftsmanship:</b> Our dedicated team combines years of
            experience with unmatched expertise to deliver exceptional
            craftsmanship on every project. From meticulous planning to flawless
            execution, we pay meticulous attention to detail, ensuring that each
            element of your decorative concrete installation is executed with
            precision and care. With a commitment to excellence, creativity, and
            innovation, we transform your ideas into stunning realities that
            surpass your expectations.
          </ListItem>
          <ListItem>
            <b>Durable and Low Maintenance:</b> Beyond its aesthetic appeal,
            decorative concrete is renowned for its durability and ease of
            maintenance. Engineered to withstand the test of time, our premium
            concrete solutions are resistant to cracks, stains, and other common
            forms of wear and tear. With minimal upkeep requirements, you can
            enjoy long-lasting beauty and functionality without the hassle of
            frequent maintenance, making decorative concrete an ideal choice for
            busy homeowners and commercial property owners alike.
          </ListItem>
          <ListItem>
            <b>Enhanced Curb Appeal:</b> Elevate the visual appeal of your
            property with a stunning decorative concrete surface that commands
            attention and admiration. Whether it's a welcoming driveway, an
            inviting walkway, or a captivating patio, our custom-designed
            concrete installations add instant charm, character, and
            sophistication to any outdoor space. Create a lasting impression and
            increase the value of your property with our premium decorative
            concrete solutions.
          </ListItem>
          <ListItem>
            <b>Environmentally Friendly Options:</b> Committed to sustainability
            and environmental stewardship, we offer a range of eco-friendly
            decorative concrete options that minimize carbon footprint and
            reduce environmental impact. From recycled materials to
            energy-efficient processes, our sustainable practices ensure that
            your decorative concrete project not only enhances the beauty of
            your surroundings but also contributes to a greener, more
            sustainable future for generations to come.
          </ListItem>
          <ListItem>
            <b>Competitive Pricing:</b> Experience the perfect blend of quality
            and affordability with our competitively priced decorative concrete
            services. Despite our unwavering commitment to excellence, we
            believe that premium craftsmanship should be accessible to all.
            That's why we strive to offer competitive pricing without
            compromising on quality, ensuring that you receive exceptional value
            for your investment. With transparent pricing and no hidden costs,
            you can trust us to deliver outstanding results that exceed your
            expectations, without breaking the bank.
          </ListItem>
        </UnorderedList>

        <Text>
          Choose Thornton Concrete for all your decorative concrete needs in
          Thornton, CO. Our expertise in creating beautiful, functional, and
          durable concrete surfaces will transform your property into a genuine
          work of art.
        </Text>
      </Stack>
    </Box>
  );
};
