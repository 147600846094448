export const posts = [
    {
        id: '0',
        title: 'Top Quality Commercial Concrete Services in Thornton, Colorado',
        excerpt: 'Thornton Concrete, your trusted partner for all commercial concrete needs. Don\'t let substandard concrete work affect your business. With our quality commercial concrete services, you can enhance the aesthetics and functionality of your commercial premises. Call us at 475-242-8150.',
        slug: 'quality-commercial-concrete-services-thornton',
        tags: ['Commercial Concrete', 'Thornton Concrete'],
        metaDescription: 'Looking for top-notch commercial concrete services in Thornton, Colorado? Thornton Concrete offers superior, durable, and business-oriented concrete solutions.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        id: '1',
        title: 'Stunning Driveways & Patios by Thornton Concrete in Thornton, Colorado',
        excerpt: 'Looking for an upgrade to your outdoor spaces? Thornton Concrete specializes in designing and creating beautiful, durable driveways and patios. Make your outdoor spaces a reflection of your personal style with us. Call us at 475-242-8150.',
        slug: 'stunning-driveways-patios-thornton',
        tags: ['Driveways', 'Patios', 'Thornton Concrete'],
        metaTitle: 'Driveways & Patios by Thornton Concrete',
        metaDescription: 'Thornton Concrete excels at designing and creating stunning driveways and patios in Thornton, Colorado. Enhance your outdoor spaces with us.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'Boost Your Property\'s Curb Appeal with Decorative Concrete Services in Thornton, Colorado',
        excerpt: 'Want to stand out in your neighborhood? Thornton Concrete offers innovative decorative concrete services that will boost your property\'s curb appeal. Our team combines technical expertise with artistry to deliver superior decorative concrete designs. Call us at 475-242-8150.',
        slug: 'boost-curb-appeal-decorative-concrete-thornton',
        tags: ['Decorative Concrete', 'Curb Appeal', 'Thornton Concrete'],
        metaTitle:'Decorative Concrete Services - Thornton Concrete Pro',
        metaDescription: 'Enhance curb appeal with expert designs and techniques in Thornton. Transform your space with decorative concrete.',
        lastModified: new Date('2023-12-20').toISOString(),
        createdAt: new Date('2023-12-20').toISOString()
    },
    {
        id: '3',
        title: 'Enhance Your Home\'s Beauty with Stamped Concrete: The Art of Curb Appeal',
        excerpt: 'Are you ready to transform your ordinary driveway, walkway, or patio into a work of art? Discover the beauty and functionality of stamped concrete from Thornton Concrete. Elevate your Thornton property with stunning decorative concrete designs that leave a lasting impression.',
        slug: 'enhance-home-beauty-stamped-concrete-curb-appeal',
        tags: ['Stamped Concrete', 'Curb Appeal', 'Decorative Concrete', 'Thornton'],
        metaTitle:'Stamped Concrete for Curb Appeal - Thornton Concrete Pro',
        metaDescription: 'Elevate your Thornton property with stunning stamped concrete designs. Learn how stamped concrete can enhance your home\'s beauty and curb appeal.',
        lastModified: new Date('2024-1-04').toISOString(),
        createdAt: new Date('2024-1-04').toISOString()
    }
]
