import React from 'react';
import {SiteData} from "../../Constants/siteData";
import concrete4 from '../../assets/images/concrete4.webp'
import {Image, Link, Box} from "@chakra-ui/react";

const Experience02 = () => {
    const headerData = {
        img: 'images/about/call_img.png',
    };
    return (
        <Box pt={{base: '0', lg: 0}} px={{base: 3, md: 0}}>
            <div className="experience about_bg_image another_bg_image">
                <div className="upper_sm_img">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="group_image_holder">
                                    <Image src={concrete4} alt="construction worker"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="experience_content experience_content_margin ">
                                    <div className="section_header">
                                        <span className="section_sub_title">{SiteData.homepage.subheading}</span>
                                        <h2 className="section_title">{SiteData.homepage.heading}</h2>
                                        <p className="short_detail">{SiteData.homepage.detail}</p>
                                        {SiteData.homepage.description}
                                        <Link href={SiteData.telLink} _hover={{textDecoration: 'none'}}>
                                            <div className="section_call">
                                                <div className="call_image">
                                                    <Image src={headerData.img} alt=""/>
                                                </div>

                                                <div className="call_info">
                                                    <p>Call Us anytime. <span>We are here for you.</span></p>
                                                    <div>{SiteData.phoneNumber}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="floating_object floating_object_7"><img src="images/about/s_b.png" alt="" /></div>*/}
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default Experience02;