import React from "react";
import { Button, Heading, Stack, Text } from "@chakra-ui/react";
import { SiteData } from "./siteData";

export const BlogPost5 = () => {
  return (
    <Stack spacing={3}>
      <Heading as="h2" size="md">
        Enhance Your Property's Beauty with Stamped Concrete
      </Heading>
      <Text>
        Welcome to <strong>Thornton Concrete</strong>, your trusted partner for
        exquisite stamped concrete services in Thornton, Colorado. Our
        commitment to quality and precision is unmatched in the industry. With
        our stamped concrete solutions, you can transform your property into a
        masterpiece of beauty and durability.
      </Text>

      <Heading as="h2" size="md">
        Experienced Stamped Concrete Artisans
      </Heading>
      <Text>
        Our team comprises skilled artisans with years of experience in crafting
        stunning stamped concrete designs. Whether it's a driveway, patio, or
        walkway, our professionals ensure every detail is meticulously executed
        to elevate your property's aesthetics. With a keen eye for design and a
        dedication to craftsmanship, we bring your vision to life with precision
        and expertise.
      </Text>

      <Heading as="h2" size="md">
        Customized Stamped Concrete Solutions
      </Heading>
      <Text>
        Thornton Concrete offers tailor-made stamped concrete solutions for
        every need. We understand that every project is unique, and we work
        closely with you to create custom designs that suit your style and
        preferences. From intricate patterns to bold textures, our stamped
        concrete solutions are as unique as your property, adding character and
        charm to every surface.
      </Text>

      <Heading as="h2" size="md">
        Quality Materials and Innovative Techniques
      </Heading>
      <Text>
        We stay at the forefront of the industry by utilizing premium materials
        and innovative techniques. This ensures not only the durability but also
        the visual appeal of your stamped concrete surfaces, making them stand
        the test of time. With a focus on quality craftsmanship and attention to
        detail, we deliver stamped concrete installations that exceed
        expectations and enhance the beauty of your property.
      </Text>

      <Heading as="h2" size="md">
        Your Satisfaction Is Our Priority
      </Heading>
      <Text>
        At Thornton Concrete, customer satisfaction is paramount. We collaborate
        closely with you, from the design phase to project completion, to ensure
        that your vision is realized, and your expectations are exceeded. With
        open communication and a commitment to excellence, we strive to create
        stamped concrete surfaces that not only meet but surpass your
        expectations, leaving you delighted with the results.
      </Text>

      <Heading as="h2" size="md">
        Expert Consultation and Design Services
      </Heading>
      <Text>
        Our expert team offers personalized consultation and design services to
        bring your stamped concrete vision to life. From initial concept to
        final execution, we guide you through every step of the process,
        providing insights and recommendations to ensure the success of your
        project. With our expertise and attention to detail, we help you make
        informed decisions and achieve the perfect stamped concrete solution for
        your property.
      </Text>

      <Heading as="h2" size="md">
        Comprehensive Maintenance and Care
      </Heading>
      <Text>
        Beyond installation, Thornton Concrete provides comprehensive
        maintenance and care services to preserve the beauty and longevity of
        your stamped concrete surfaces. Our team offers routine inspections,
        cleaning, and sealing to protect against wear and tear, ensuring that
        your stamped concrete continues to look its best for years to come. With
        our proactive approach to maintenance, you can enjoy your stunning
        stamped concrete surfaces with peace of mind.
      </Text>

      <Heading as="h2" size="md">
        Elevate Your Property with Stamped Concrete Today
      </Heading>
      <Text>
        Ready to enhance your property's beauty with stamped concrete? Choose
        Thornton Concrete for unmatched expertise, professionalism, and
        dedication to excellence. Contact us now to schedule a consultation and
        take the first step towards transforming your outdoor space into a work
        of art with stamped concrete.
      </Text>

      <Button
        as={"a"}
        href={SiteData.telLink}
        colorScheme="blue"
        size="lg"
        mb={6}
        _hover={{ color: "white" }}
      >
        Contact Us Today
      </Button>
    </Stack>
  );
};
