import { useEffect, useState } from 'react';
import { SiteData } from "../Constants/siteData";
import contentfulClientApi from "../Client/client";
import { getBlogImage } from "../Utils/metaHelpers";
import localBlogData from '../data/blogData.json';

const useBlogPosts = () => {
    const [posts, setPosts] = useState(SiteData.blog.posts.map((p) => ({
        ...p,
        image: getBlogImage(p.id)
    })));
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (localBlogData && localBlogData.length > 0) {
            setPosts(localBlogData);
            setIsLoading(false);
            return;
        }

        if (!process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        contentfulClientApi.getEntries({ content_type: 'blogPost' })
            .then((response) => {
                const contentfulPosts = response.items
                    .filter((item) => item.fields.website[0].includes(SiteData.homepage.url))
                    .map((entry, index) => {
                        return ({
                            id: `${index}-${entry.fields.slug}`, // Unique ID
                            title: entry.fields.title,
                            excerpt: entry.fields.excerpt,
                            body: entry.fields.body,
                            slug: entry.fields.slug,
                            metaDescription: entry.fields.metaDescription,
                            metaTitle: entry.fields.metaTitle,
                            createdAt: entry.sys.createdAt,
                            lastModified: entry.sys.lastModified,
                            image: `https://${entry.fields.image.fields.file.url.replace(/^\/\//, '')}`,
                            imageAltText: entry?.fields?.image?.fields?.file?.title || entry?.fields?.title
                        })
                    });

                // Combine and filter out duplicates
                const combinedPosts = [...contentfulPosts, ...posts].reduce((acc, current) => {
                    const x = acc.find(item => item.id === current.id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setPosts(combinedPosts);
            })
            .catch((err) => {
                console.error(err);
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []); // Empty dependency array

    return { posts, isLoading, error };
};

export default useBlogPosts;