import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SEO} from "../Components/SEO/SEO";
import {Box, Link, List, ListItem} from "@chakra-ui/react";

const urls = [
    "https://www.yelp.com/biz/thornton-concrete-professionals-thornton",
    "https://find-open.com/thornton/thornton-concrete-professionals-14399475",
    "https://www.brownbook.net/business/52548064/thornton-concrete-professionals/",
    "http://arvada.bizlistusa.com/business/5407103.htm",
    "https://www.cylex.us.com/company/thornton-concrete-professionals-37569335.html#google_vignette",
    "https://foursquare.com/v/thornton-concrete-professionals/65f7eceb54445b4bc02aded8",
    "http://www.askmap.net/location/6860354/united-states/thornton-concrete-professionals",
    "https://tupalo.com/en/thornton-colorado/thornton-concrete-professionals",
    "https://www.merchantcircle.com/thornton-concrete-professionals-thornton-co",
    "https://thornton-concrete-professionals.hub.biz/",
    "https://www.callupcontact.com/b/businessprofile/Thornton_Concrete_Professionals/8970742",
    "https://us.enrollbusiness.com/BusinessProfile/6608770/Thornton-Concrete-Professionals-Thornton-CO-80241",
    "https://i.imgur.com/VKa74wo.png",
    "https://www.n49.com/biz/5889160/thornton-concrete-professionals-co-thornton-12349-grant-st-80241/",
    "https://ask-dir.org/Thornton-Concrete-Professionals_312230.html",
    "https://www.freelistingusa.com/listings/thornton-concrete-professionals",
    "https://colorado.bizhwy.com/thornton-concrete-professionals-id22267.php",
    "https://www.hotfrog.com/company/3507a659caf2928ef51ba3c87dbbf0cc/thornton-concrete-professionals/thornton/contractor-equipment-services",
    "https://www.insertbiz.com/listing/12349-grant-st-thornton-co-80241-thornton-concrete-professionals/",
    "https://www.cityfos.com/company/Thornton-Concrete-Professionals-in-Thornton-CO-23004577.htm",
    "https://www.find-us-here.com/businesses/Thornton-Concrete-Professionals-Thornton-Colorado-USA/34020498/",
    "https://www.a-zbusinessfinder.com/business-directory/Thornton-Concrete-Professionals-Thornton-Colorado-USA/34020498/",
    "https://link-man.org/Thornton-Concrete-Professionals_318133.html",
    "https://www.evernote.com/shard/s491/sh/3516ca52-f7ff-6eb5-4c64-52f0cecaefd5/gvkvdHuEicy7JoYuaSZklku2Qnq1An86OLIxA6v52uuqdnN3eAcJSApzAA",
    "https://www.successcenter.com/colorado/thornton/services/thornton-concrete-professionals",
    "https://classified4free.net/588/posts/3-Services/27-Other/1906627-Thornton-Concrete-Professionals.html",
    "https://speakerdeck.com/thorntonconcrete",
    "http://www.place123.net/place/thornton-concrete-professionals-thornton-co-united-states",
    "https://www.startus.cc/company/thornton-concrete-professionals",
    "https://freewebads.us/588/posts/3-Services/27-Other/1901671-Thornton-Concrete-Professionals.html",
    "https://www.iformative.com/product/thornton-concrete-professionals-p2436633.html",
    "https://www.nextbizthing.com/thornton-co/construction-20-contractors/thornton-concrete-professionals",
    "https://telegra.ph/Thornton-Concrete-Professionals-03-18",
    "https://posthereads.com/588/posts/3-Services/27-Other/1939792-Thornton-Concrete-Professionals.html",
    "https://firmania.com/thornton/thornton-concrete-professionals-14840706",
    "http://www.travelful.net/location/5402295/united-states/thornton-concrete-professionals",
    "https://teleadreson.com/thornton-concrete-professionals,12349-grant-st,-thornton,-co-80241-qZGSAKmRkgA.html",
    "https://globalcatalog.com/thorntonconcreteprofessionals.us",
    "https://www.diggerslist.com/65f8009832852/about",
    "https://sites.bubblelife.com/community/thornton_concrete_professionals",
    "https://www.manta.com/c/m1wf5g8/thornton-concrete-professionals",
    "https://addgoodsites.com/details.php?id=544540",
    "https://www.irooni.co/thornton-co/home-services/thornton-concrete-professionals",
    "http://www.spiritualpool.com/thornton-co/thornton-concrete-professionals",
    "http://statepromoted.com/directory/listingdisplay.aspx?lid=29225",
    "http://jeffersoncountyco.bizlistusa.com/business/5407103.htm",
    "http://directorios.us/thornton-co/home-services/thornton-concrete-professionals",
    "https://www.provenexpert.com/thornton-concrete-professionals/?mode=preview",
    "https://www.quora.com/profile/Thornton-Concrete-Professionals",
    "https://www.fixerhub.com/thornton-co/home-service/thornton-concrete-professionals",
    "https://homedirectory.biz/Thornton-Concrete-Professionals_361054.html",
    "https://www.adlocalpages.com/thornton-co/construction-contractors/thornton-concrete-professionals",
    "https://cally.com/event/index/fkwgwju2z6anuxhw",
    "https://issuu.com/thorntonconcrete",
    "https://batchgeo.com/map/82f3224f9788b1ca15e245e0a70fe20f",
    "https://www.earthmom.org/thornton-co/construction-contracting/thornton-concrete-professionals",
    "https://www.bestincom.com/thornton-co/contractors/thornton-concrete-professionals",
    "https://www.ikeanded.com/directory/listingdisplay.aspx?lid=169202",
    "https://geocha-production.herokuapp.com/maps/129344-thornton-concrete-professionals",
    "https://steeldirectory.net/details.php?id=271885",
    "https://harddirectory.net/Thornton-Concrete-Professionals_264115.html",
    "https://www.nextbizmaker.com/thornton-co/business-services/thornton-concrete-professionals",
    "https://freebestads.com/588/posts/3-Services/27-Other/1360403-Thornton-Concrete-Professionals.html",
    "https://www.consultsdirect.com/thornton-co/consultant/thornton-concrete-professionals",
    "https://www.counselingnearme.com/thornton-co/in-person-counseling/thornton-concrete-professionals",
    "https://gravatar.com/thorntonconcreteproco",
    "https://ezclassifiedads.com/588/posts/3-Services/27-Other/1959330-Thornton-Concrete-Professionals.html",
    "https://www.zeemaps.com/map?group=4944781&location=12349%20Grant%20St%2C%20Thornton%2C%20CO%2C%2080241%2C%20USA&add=1#",
    "https://co.yalwa.com/ID_141875169/Thornton-Concrete-Professionals.html",
    "https://yellow.place/en/thornton-concrete-professionals-thornton-co-usa",
    "https://www.mapcustomizer.com/map/Thornton%20Concrete%20Professionals",
    "https://flipboard.com/@ThorntonCon4esj?from=share&utm_source=flipboard&utm_medium=curator_share",
    "http://www.4mark.net/story/11612661/thornton-concrete-professionals",
    "https://www.preferredprofessionals.com/thornton-co/construction-contractors/thornton-concrete-professionals",
    "https://www.tripadvisor.com/Profile/thorntonconcretep",
    "https://www.4shared.com/u/WuyyRPpe/thorntonconcreteproco.html",
    "https://www.cargodirectory.co/thornton-co/seaports/thornton-concrete-professionals",
    "https://letterboxd.com/thorntonco/",
    "https://postherefree.com/588/posts/3-Services/27-Other/1918905-Thornton-Concrete-Professionals.html",
    "https://www.expatriates.com/cls/55670257.html?1710759511",
    "https://classified4u.biz/588/posts/3-Services/27-Other/1889625-Thornton-Concrete-Professionals.html",
    "https://getadsonline.com/588/posts/3-Services/27-Other/1989514-Thornton-Concrete-Professionals.html",
    "https://mapfling.com/qqeah28",
    "https://www.linkcentre.com/profile/thorntonconcrete/",
    "http://locals101.com/directory/listingdisplay.aspx?lid=62280",
    "https://www.openstreetmap.org/user/Thornton%20Concrete%20Professionals",
    "http://jet-links.com/Thornton-Concrete-Professionals_327712.html",
    "http://localbranded.com/directory/listingdisplay.aspx?lid=13169",
    "https://www.pageorama.com/?p=https-www-thorntonconcretepro-com",
    "https://www.adslov.com/588/posts/3-Services/27-Other/1884432-Thornton-Concrete-Professionals.html",
    "https://www.bunity.com/thornton-concrete-professionals",
    "https://clicksordirectory.com/details.php?id=401247",
    "http://arvada.bizlistusa.com/business/5407103.htm",
    "http://jeffersoncountyco.bizlistusa.com/business/5407103.htm?guid=A5C01251-E11C-4BD2-BA75-352AD90617DC",
    "https://buynow-us.com/660151-thornton-concrete-professionals/details.html",
    "https://startupxplore.com/en/person/thornton-concrete-professionals",
    "https://www.cakeresume.com/me/thornton-concrete-professionals",
    "https://www.thecityclassified.com/author/thorntonconcrete/",
    "https://www.40billion.com/company/456495500",
    "https://www.findmyorganizer.com/profile.b.507.r.72636.u.f74dde.html",
    "https://businesslistingplus.com/classified/thornton-concrete-professionals.html",
    "https://www.alltrails.com/members/thornton-concrete-professionals/about",
    "https://www.pinterest.com/Thorntonconcrete/",
    "https://www.freeads24.eu/detail.php?id=631322",
    "https://www.getlisteduae.com/listings/thornton-concrete-professionals",
    "https://www.adspostfree.com/author/thorntonconcrete/"
];
const DirectoriesOnTheWeb = () => {
    return (
        <>
            <SEO/>
            <PageHeader header={"Find us on the web!"}/>
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <List spacing={3} textAlign={'center'}>
                        {urls.map((url, index) => (
                            <ListItem key={index}>
                                <Link href={url} isExternal color="blue.500" target={"_blank"} rel={"noreferrer"}>
                                    {url}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </>
    );
};

export default DirectoriesOnTheWeb;